<adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

<adxad-modal-header>
  <h2 adxadModalTitle>{{ this.creative?.error.split('.')[1] }}</h2>
</adxad-modal-header>

<adxad-modal-content>
  <div
    [class.--column]="(mediaType !== 'native' && resizeToWidth > 450)"
    class="image-resizer"
  >
    <div class="image-cropper-wrapper">
      <image-cropper
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        [aspectRatio]="aspectRatio"
        [canvasRotation]="canvasRotation"
        [cropperMaxHeight]="cropperMaxHeight"
        [cropperMaxWidth]="cropperMaxWidth"
        [cropperMinHeight]="cropperMinHeight"
        [cropperMinWidth]="cropperMinWidth"
        [format]="format"
        [hideResizeSquares]="hideResizeSquares"
        [imageBase64]="creative?.incorrectImageUrl"
        [maintainAspectRatio]="maintainAspectRatio"
        [onlyScaleDown]="false"
        [resizeToHeight]="resizeToHeight"
        [resizeToWidth]="resizeToWidth"
        alignImage="center"
        [transform]="transform"
        [style.display]="showCropper ? null : 'none'"
      ></image-cropper>
    </div>

    <div class="image-resizer__preview">
      <img
        [src]="croppedImage"
        *ngIf="mediaType !== 'native'"
        [style.--preview-width]="resizeToWidth + 'px'"
        alt=""
      />

      <h5>{{ 'preview' | transloco }}</h5>

      <div class="image-resizer__result">
        <p>{{ 'imageSize' | transloco: {size: imageSize} }}</p>
        <p>{{ 'fileSize' | transloco }}: {{ (cropImageFileSize$ | async) / 1000 }} kB</p>

        <p
          *ngIf="isInvalidFileSize()"
          class="error"
        >
          {{ 'alert_theResultFileIsStillLarger' | transloco }}
        </p>
      </div>
    </div>
  </div>
</adxad-modal-content>

<adxad-modal-actions>
  <div class="image-resizer__actions">
    <button
      (click)="resetImage()"
      adxadIconButton
      adxadTooltip="{{ 'action_resetImage' | transloco }}"
    >
      <span class="material-icons-outlined"> refresh </span>
    </button>
    <button
      (click)="zoomIn()"
      adxadIconButton
      adxadTooltip="{{ 'action_zoomIn' | transloco }}"
    >
      <span class="material-icons-outlined"> zoom_in </span>
    </button>
    <button
      (click)="zoomOut()"
      adxadIconButton
      adxadTooltip="{{ 'action_zoomOut' | transloco }}"
    >
      <span class="material-icons-outlined"> zoom_out </span>
    </button>
    <button
      (click)="rotateRight()"
      adxadIconButton
      adxadTooltip="{{ 'action_rotateRight' | transloco }}"
    >
      <span class="material-icons-outlined"> rotate_right </span>
    </button>
    <button
      (click)="rotateLeft()"
      adxadIconButton
      adxadTooltip="{{ 'action_rotateLeft' | transloco }}"
    >
      <span class="material-icons-outlined"> rotate_left </span>
    </button>
  </div>

  <button
    (click)="close(false)"
    adxadStrokedButton
  >
    {{ 'action_cancel' | transloco }}
  </button>

  <adxad-submit-button
    (click)="submit()"
    [disabled]="isInvalidFileSize()"
  >
    {{ 'action_save' | transloco }}
  </adxad-submit-button>
</adxad-modal-actions>
