<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ 'modal_titleEditClickUrl' | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <div class="notifications">
      <span class="material-icons"> notifications </span>

      {{ 'notification_campaignWasBlockedByIncorrectClickUrl' | transloco }}
    </div>

    <adxad-form-field class="field-group">
      <adxad-textarea
        formControlName="clickUrl"
        label="{{ 'label_clickUrl' | transloco }}"
        (blurEvent)="setCursorPosition($event)"
        [fixWidth]="true"
      ></adxad-textarea>
    </adxad-form-field>

    <div
      class="invalid-url"
      *ngIf="isInvalidUrl"
    >
      {{ 'notification_thisUrlIsInvalid' | transloco }}
    </div>

    <adxad-holders
      [holders]="dict.placeholders.data"
      [text]="form.get('clickUrl').value"
      (changeHolder)="toggleHolder($event)"
      label="{{ 'label_holders' | transloco }}"
    ></adxad-holders>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <button
        adxadButton
        [disabled]="isDisabledBtn"
        (click)="submit()"
        class="w100"
      >
        {{ 'action_save' | transloco }}
      </button>
    </div>
  </adxad-modal-actions>
</ng-container>
