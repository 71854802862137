<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ 'modal_titleCopyCampaignsToProject' | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <div class="campaigns-names">
      <div class="campaigns-names__title">{{ names.length + ' ' + ((names.length > 1 ? 'campaigns' : 'campaign') | transloco) }}</div>

      <ul class="campaigns-names__list">
        <li
          class="campaigns-names__name"
          *ngFor="let name of names"
        >
          {{ name }}
        </li>
      </ul>
    </div>

    <adxad-form-field class="w100">
      <adxad-select
        placeholder="{{ 'placeholder_selectProject' | transloco }}"
        label="{{ 'label_projectName' | transloco }}"
        formControlName="project"
        [options]="dict.projects.data"
        [lazy]="dict.projects.isLazy"
        [filterable]="true"
        (selectOption)="filterProjects('')"
        (scrollOnBottom)="loadProjects()"
        (filterValue)="filterProjects($event)"
      ></adxad-select>
      <app-show-errors [control]="form.get('project')"></app-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        {{ 'action_copy' | transloco }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
