import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DspService } from '../../../../../../core/services/dsp.service';
import { CreativesService } from '../../creatives.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { Creative, Dicts } from '../../../../../../core/interface';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-copy-form',
  templateUrl: './copy-form.component.html',
  styleUrls: ['./copy-form.component.scss']
})
export class CopyFormComponent implements OnInit {
  public isLoading = false;
  public isLoadingSubmit = false;
  public form: UntypedFormGroup;
  public names = [];
  public dict = {} as Dicts;

  private creatives: Creative[];
  private advertFormat;
  private mediaType;
  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private dspService: DspService,
    private creativesService: CreativesService,
    private alerts: AdxadAlerts,
    private config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.creatives = this.config.data.creatives;
    this.names = this.creatives.map(x => x.name);
    this.advertFormat = this.creatives[0].campaignFormat;
    this.mediaType = this.creatives[0].campaignMediaType;

    this.createForm();
    this.loadData();
  }

  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getCampaignsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.dict.campaigns = result;
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  createForm(): void {
    this.form = this.fb.group({
      creatives: [this.creatives.map(x => x.id)],
      campaignId: ['', Validators.required]
    });
  }

  loadCampaigns() {
    const dict = this.dict.campaigns;

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.dspService
      .getCampaignsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;

          dict.data = dict.data.concat(result.data);

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  filterCampaigns(value) {
    const dict = this.dict.campaigns;

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;
    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    this.dspService
      .getCampaignsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = result.data;

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  submit(): void {
    if (this.isLoadingSubmit || !this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;

    this.creativesService
      .copyCreatives(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingSubmit = false;
          if (result.status === 'OK') {
            this.alerts.success(this.translate.translate('alert_creativesSuccessfullyCopied'), 3000);
            this.close(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
