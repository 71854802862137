<div class="header">
  <section
    [ngClass]="{'hide': isNotCreated}"
    class="adxad-toolbar"
  >
    <div class="adxad-actions">
      <button
        (click)="multiChangeStatus(Status.stopped)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn --adaptive-btn"
        icon="pause"
        iconHoverColor="error"
      >
        {{ 'action_stop' | transloco }}
      </button>

      <button
        (click)="multiChangeStatus(Status.active)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn  --adaptive-btn"
        icon="play_arrow"
        iconHoverColor="success"
      >
        {{ 'action_activate' | transloco }}
      </button>

      <div
        [adxadTooltip]="isDisableMultiActions? ('tooltips_creativesCopyObjects' | transloco) : null"
        class="action-btn"
      >
        <button
          (click)="multiCopyCreatives()"
          [disabled]="isDisableMultiActions"
          adxadStrokedButton
          icon="filter_none"
          class="--adaptive-btn"
        >
          {{ 'action_copy' | transloco }}
        </button>
      </div>

      <div
        [adxadTooltip]="isDisableMultiActions? ('tooltips_creativesDeletingObjects' | transloco) : null"
        class="action-btn"
      >
        <button
          (click)="multiChangeStatus(4)"
          [disabled]="isDisableMultiActions"
          adxadStrokedButton
          icon="delete_outline"
          iconHoverColor="error"
          class="--adaptive-btn"
        >
          {{ 'action_delete' | transloco }}
        </button>
      </div>
    </div>
  </section>

  <section class="filter-toolbar">
    <adxad-calendar
      (changeDates)="filter.changeCalendarDates($event)"
      [from]="filter.calendar?.value?.calendar.from"
      [to]="filter.calendar?.value?.calendar.to"
    ></adxad-calendar>

    <div class="filter-toolbar__actions">
      <adxad-dg-column-selector
        *ngIf="columns.length"
        [columns]="columns"
        gridPrefix="creativesGridHiddenColumns"
        class="--adaptive-action"
      ></adxad-dg-column-selector>

      <button
        (click)="goToDeleted()"
        adxadStrokedButton
        class="filter-toolbar__deleted-btn"
      >
        {{ 'action_deleted' | transloco }}
      </button>

      <adxad-filter-actions
        (applyFilter)="applyFilter()"
        (clearFilters)="clearFilters()"
        (openFilter)="openFilter()"
        [count]="filter.count"
        [notApplied]="filter.isNotApplied"
      ></adxad-filter-actions>
    </div>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{'hide': isNotFound || isNotCreated}"
></adxad-dynamic-grid>

<adxad-grid-not-found
  *ngIf="isNotFound"
  (clearFilters)="clearFilters()"
></adxad-grid-not-found>

<section
  class="not-created"
  *ngIf="isNotCreated"
>
  <img
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
    alt=""
  />
  <h2 class="not-created__title">{{ 'noCreatives' | transloco }}</h2>

  <p
    class="not-created__text"
    [innerHTML]="'noCreativesYet' | transloco"
  ></p>
</section>

<adxad-paginator (changePage)="loadGrid()"></adxad-paginator>
