<div
  *ngIf="creatives().length"
  [formGroup]="form"
  class="old-creatives"
>
  <div class="old-creatives__header row">
    <div class="col-7">
      <div class="old-creatives__title">{{ 'campaignCreatives' | transloco }}</div>

      <div class="old-creatives__statuses">
        <span class="old-creatives__status">
          {{ 'status_approved' | transloco }}:

          <span class="old-creatives__status-approved"> {{ creativesCount('approved') }} </span>
        </span>

        <span class="old-creatives__status">
          {{ 'status_creativePending' | transloco }}:

          <span class="old-creatives__status-pending"> {{ creativesCount('pending') }} </span>
        </span>

        <span class="old-creatives__status">
          {{ 'status_creativeRejected' | transloco }}:

          <span class="old-creatives__status-rejected"> {{ creativesCount('rejected') }} </span>
        </span>
      </div>
    </div>

    <div class="col-5 text-right">
      <button
        adxadLinkButton
        (click)="cancelChanges()"
        [disabled]="isDisableCancelBtn()"
      >
        {{ 'action_cancel' | transloco }}
      </button>
      <button
        adxadButton
        (click)="submit()"
        [disabled]="isDisableSaveBtn()"
      >
        {{ 'action_save' | transloco }}
      </button>
    </div>
  </div>

  <div
    class="loader"
    *ngIf="isLoading"
  >
    <adxad-loader [diameter]="40"></adxad-loader>
  </div>

  <div
    class="row"
    *ngIf="!isLoading"
  >
    <div
      class="col-12 old-creatives__item"
      [ngClass]="itemClassName"
      formArrayName="creatives"
      *ngFor="let creative of campaign.creatives; index as i"
    >
      <adxad-creative-preview
        [control]="getCreative(i)"
        [mediaType]="campaign.mediaType.id"
        [format]="campaign.format"
        [creative]="creative"
        [index]="i"
      ></adxad-creative-preview>
    </div>
  </div>
</div>
