import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { CampaignViewComponent } from './components/campaign-view/campaign-view.component';
import { CampaignsGridComponent } from './components/campaigns-grid/campaigns-grid.component';
import { CopyFormComponent } from './components/copy-form/copy-form.component';
import { CampaignsDeletedGridComponent } from './components/campaigns-deleted-grid/campaigns-deleted-grid.component';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { ClickUrlFormComponent } from './components/click-url-form/click-url-form.component';
import { CampaignsWizardComponent } from './components/campaigns-wizard/campaigns-wizard.component';
import { TranslocoModule } from '@jsverse/transloco';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule, TranslocoModule];

const ENTRY_COMPONENTS = [
  CampaignsGridComponent,
  CampaignViewComponent,
  CopyFormComponent,
  CampaignsDeletedGridComponent,
  ClickUrlFormComponent,
  CampaignsWizardComponent
];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS]
})
export class CampaignsModule {}
