import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MassAddCreativesComponent } from '../../modules/trade-desk/modules/creatives-form/components/mass-add-creatives/mass-add-creatives.component';
import { translate } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class CreativesFormGuard {
  canDeactivate(
    component: MassAddCreativesComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ((component.newCreatives && component.newCreatives.creatives.length) || component.creatives?.form.dirty) {
      return confirm(translate('alert_hasUnsavedDataAreUSure'));
    }

    return true;
  }
}
