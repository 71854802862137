import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTE } from '../../../../../../core/routes';
import { ProfileService } from '../../../../../../core/services/profile.service';
import { LayoutService } from '../../../../../../layout/layout.service';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-campaigns-wizard',
  templateUrl: './campaigns-wizard.component.html',
  styleUrls: ['./campaigns-wizard.component.scss']
})
export class CampaignsWizardComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  isShowWizard = false;
  activeStep = 1;
  steps = ['wizard_startWorkingCreateCampaign', 'wizard_moreAboutPlatform', 'wizard_ourTeamIsHereForYou'];

  constructor(
    private layoutService: LayoutService,
    private profileService: ProfileService,
    private router: Router,
    public globicaService: GlobicaService
  ) {}

  ngOnInit(): void {
    this.profileService.profile$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: profile => {
        const wizards = profile.wizards;
        if (wizards && wizards.indexOf('campaigns') === -1) {
          this.isShowWizard = true;
          this.globicaService.trackEventGoals('formOnBoardingFirstStep_show');
          document.body.classList.add('hidden');
        }
      }
    });
  }

  /**
   * Close Wizard
   * Mark wizard as watched
   * @param step - wizard active step index
   */
  close(step?: number): void {
    const stepCloseKey = [
      'buttonOnBoardingFirstStepClose_click',
      'buttonOnBoardingSecondStepClose_click',
      'buttonOnBoardingThirdStepClose_click'
    ];

    if (step !== undefined) {
      this.globicaService.trackEventGoals(`${stepCloseKey[step - 1]}`);
    }

    this.isShowWizard = false;
    document.body.classList.remove('hidden');

    this.layoutService
      .setWizardShowed({ name: 'campaigns' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result.status === 'OK') {
          const profile = {
            wizards: ['campaigns', ...this.profileService.profile.wizards],
            ...this.profileService.profile
          };

          this.profileService.setProfile(profile);
        }
      });
  }

  /**
   * Go to next step
   */
  nextStep(): void {
    this.globicaService.trackEventGoals(
      this.activeStep === 1 ? 'buttonOnBoardingFirstStepNext_click' : 'buttonOnBoardingSecondStepNext_click'
    );

    this.activeStep++;

    if (this.activeStep === 2) {
      this.globicaService.trackEventGoals('formOnBoardingSecondStep_show');

      if (!this.layoutService.isMenuStaticOpened) {
        this.layoutService.toggleMenuStaticOpened();
      }
    } else {
      this.globicaService.trackEventGoals('formOnBoardingThirdStep_show');
      if (this.layoutService.isMenuStaticOpened) {
        this.layoutService.toggleMenuStaticOpened();
      }
    }
  }

  /**
   * Close wizard & go to guidelines page
   */
  showMore(): void {
    this.globicaService.trackEventGoals('buttonOnBoardingThirdStepMore_click');
    this.router.navigate([APP_ROUTE.help]).then(() => this.close());
  }
}
