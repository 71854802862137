import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { CreativesGridComponent } from './components/creatives-grid/creatives-grid.component';
import { CopyFormComponent } from './components/copy-form/copy-form.component';
import { CreativeViewComponent } from './components/creative-view/creative-view.component';
import { CreativesDeletedGridComponent } from './components/creatives-deleted-grid/creatives-deleted-grid.component';
import { CreativeEditFormComponent } from './components/creative-edit-form/creative-edit-form.component';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { TranslocoModule } from '@jsverse/transloco';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule, TranslocoModule];

const ENTRY_COMPONENTS = [
  CreativesGridComponent,
  CreativeViewComponent,
  CopyFormComponent,
  CreativesDeletedGridComponent,
  CreativeEditFormComponent
];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS]
})
export class CreativesModule {}
