import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../../../../core/services/message.service';
import { Dict, Dicts } from '../../../../../../core/interface';
import { DspService } from '../../../../../../core/services/dsp.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { CampaignsService } from '../../campaigns.service';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-click-url-form',
  templateUrl: './click-url-form.component.html',
  styleUrls: ['./click-url-form.component.scss']
})
export class ClickUrlFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public dict = {} as Dicts;

  private destroyRef = inject(DestroyRef);
  private cursorPosition = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private dspService: DspService,
    private alerts: AdxadAlerts,
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private campaignService: CampaignsService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.loadHolders();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      id: this.config.data.campaignId || this.config.data.id,
      clickUrl: [this.config.data.clickUrl, [Validators.required, Validators.minLength(4)]]
    });
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  /**
   * @return {boolean} flag of disabled submit btn
   */
  get isDisabledBtn(): boolean {
    return !this.form.dirty || this.form.invalid || this.isInvalidUrl;
  }

  /**
   * Load placeholders dict
   */
  loadHolders(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getPlaceholders(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.dict.placeholders = result;
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Add placeholder in iframe
   * or replace all same holders
   *
   * @param holder
   */
  toggleHolder(holder: Dict): void {
    let code = this.form.get('clickUrl').value;

    if (code.indexOf(holder.id) !== -1) {
      const re = new RegExp(holder.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + holder.id + code.slice(this.cursorPosition);
    }
    this.form.get('clickUrl').setValue(code);
  }

  /**
   * @return {boolean} url not included 'https://'
   */
  get isInvalidUrl(): boolean {
    const value = this.form.get('clickUrl').value;
    return !value.startsWith('https://') && !value.startsWith('http://');
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Save changes
   */
  submit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    this.campaignService
      .setCampaignClickUrl(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          this.isLoading = false;

          if (response.status === 'OK') {
            this.close(true);
            this.messageService.add('reload-campaigns-grid', { submit: true });
            this.alerts.success(this.translate.translate('alert_clickUrlSuccessfullyChanged'), 3000);
          }
        },
        error: () => (this.isLoading = false)
      });
  }
}
