import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicGridAction, FilterChanges, FiltersConfig, GridRequest, Project, ProjectData } from '../../../../../../core/interface';
import { Router } from '@angular/router';
import { DspService } from '../../../../../../core/services/dsp.service';
import { ProjectsService } from '../../projects.service';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { ProjectViewComponent } from '../project-view/project-view.component';
import { RolesService } from '../../../../../../core/services/roles.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { ProjectFormComponent } from '../project-form/project-form.component';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { APP_ROUTE, DESK_ROUTE } from '../../../../../../core/routes';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { BreadcrumbsService } from '../../../../../../core/services/breadcrumbs.service';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { MessageService } from '../../../../../../core/services/message.service';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Status } from '../../../../../../core/configs';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-projects-grid',
  templateUrl: './projects-grid.component.html',
  styleUrls: ['./projects-grid.component.scss'],
  providers: [FiltersService]
})
export class ProjectsGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isLoadingFilters = false;
  public isLoadingChangeStatus = false;
  public isNotCreated = false;
  public isNotFound = false;
  public readonly Status = Status;

  private destroyRef = inject(DestroyRef);

  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<ProjectData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.projectsService.getProjects(data))
  );

  constructor(
    public filter: FiltersService,
    private dspService: DspService,
    private projectsService: ProjectsService,
    private messageService: MessageService,
    private breadcrumbs: BreadcrumbsService,
    private queryParamsService: QueryParamsService,
    private roles: RolesService,
    private alerts: AdxadAlerts,
    public router: Router,
    private sidebarModal: AdxadSidebarModal,
    private modal: AdxadModal,
    private translate: TranslocoService,
    public globica: GlobicaService
  ) {}

  ngOnInit(): void {
    this.paginator.init();
    this.subscribeFilter();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: ProjectData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.messageService
      .get('reload-projects-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data.submit) {
          this.loadGrid();
        }
      });

    this.loadFilters();
  }

  /**
   * Load & init filters list;
   * Call loadGrid or show error message
   */
  loadFilters(): void {
    this.isLoadingFilters = true;

    this.dspService
      .getFilters('projects')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoadingFilters = false;

          if (status === 'OK') {
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        error: () => (this.isLoadingFilters = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator && this.paginator.limit,
      page: this.paginator && this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set projects grid
   * @param {ProjectData} result
   */
  private setGrid(result: ProjectData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * Check actions in grid
   * toggleStatus - active/stop project
   * openView - load & open detail project in sidebar modal
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }
    const project = action.value as Project;

    switch (action.action) {
      case 'toggleStatus': {
        const status = project.status === this.Status.active ? this.Status.stopped : this.Status.active;
        this.changeStatus([project], status);
        break;
      }
      case 'openView': {
        if (!action.value.id) {
          return;
        }
        this.openSidebarModal(action.value.id);
        break;
      }
      case 'openSubGrid': {
        if (!project.campaigns) {
          return;
        }
        this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.campaigns], {
          queryParams: { f_project: '[{"id":"' + project.id + '","value":"' + project.name + '"}]' }
        });
      }
    }
  }

  /**
   * Change status in selected projects
   *
   * @param {number} status
   */
  multiChangeStatus(status: number): void {
    const projects = this.dynamicGrid.getChecked();
    const ids = projects.map(x => x.id);
    let globicaKey;

    switch (status) {
      case this.Status.active: {
        globicaKey = 'buttonActivate_click';
        break;
      }
      case this.Status.stopped: {
        globicaKey = 'buttonStop_click';
        break;
      }
      case this.Status.archived: {
        globicaKey = 'buttonDelete_click';
        break;
      }
    }

    this.globica.trackEventGoals(globicaKey, { objects_type: 'projects', ids });

    this.changeStatus(projects, status);
  }

  /**
   * Change status in projects
   *
   * @param {Project[]} projects
   * @param {number} status
   */
  changeStatus(projects: Project[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: projects.map(x => x.id),
      status: status
    };

    projects.forEach(x => (x.isLoading = true));
    this.projectsService
      .changeProjectsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data) {
              this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
              projects.forEach(x => (x.isLoading = false));
              return;
            }

            projects.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                return true;
              }

              if (x.status !== obj.status) {
                let message: string;

                switch (obj.status) {
                  case Status.active: {
                    message = 'alert_projectIsActivated';
                    break;
                  }
                  case Status.stopped: {
                    message = 'alert_projectIsStopped';
                    break;
                  }
                  case Status.archived: {
                    message = 'alert_projectIsDeleted';
                    break;
                  }
                  default: {
                    message = 'alert_projectIsChanged';
                  }
                }

                this.alerts.success(this.translate.translate(message, { projectName: x.name }), 3000);
                x.status = obj.status;
              }
            });

            this.dynamicGrid.resetMultiSelect();

            if (status === Status.archived) {
              this.loadGrid();
            }
          }
        },
        error: () => {
          this.isLoadingChangeStatus = false;
          projects.forEach(x => (x.isLoading = false));
        }
      });
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  /**
   * Open detail project view
   *
   * @param id of project
   */
  openSidebarModal(id: string): void {
    if (!id) {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.breadcrumbs.reset();
    this.sidebarModal.open(ProjectViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open create project form
   */
  openForm(): void {
    this.globica.trackEventGoals('buttonCreateProject_click');
    this.modal
      .open(ProjectFormComponent, {
        width: '500px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadGrid();
        }
      });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', { filters: this.filter.globicaFilterRequest });
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Go to deleted grid
   * Set filter query params
   */
  goToDeleted(): void {
    const url = APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.deletedProjects;
    const queryParams = this.queryParamsService.get('f_');

    /** Track globica event */
    this.globica.trackEventGoals('buttonDeletedProjects_click');
    this.router.navigate([url], { queryParams });
  }

  ngOnDestroy(): void {
    this.messageService.remove('reload-projects-grid');
  }
}
