import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DspService } from '../../../../../../core/services/dsp.service';
import { CampaignsService } from '../../campaigns.service';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Dicts } from '../../../../../../core/interface';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-copy-form',
  templateUrl: './copy-form.component.html',
  styleUrls: ['./copy-form.component.scss']
})
export class CopyFormComponent implements OnInit {
  isLoading = false;
  isLoadingSubmit = false;
  form: UntypedFormGroup;
  names = [];
  dict = {} as Dicts;
  private searchValue = '';

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private dspService: DspService,
    private campaignsService: CampaignsService,
    private alerts: AdxadAlerts,
    private config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.names = this.config.data.campaigns.map(x => x.name);

    this.createForm();
    this.loadData();
  }

  /**
   * Load projects dict
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 500,
      page: 1
    };

    this.dspService
      .getProjectsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.dict.projects = result;
          this.dict.projects.isLazy = this.dict.projects.meta.total > this.dict.projects.data.length;
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  loadProjects() {
    const dict = this.dict.projects;

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.dspService
      .getProjectsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;

          dict.data = dict.data.concat(result.data);

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  filterProjects(value) {
    const dict = this.dict.projects;

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;
    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    this.dspService
      .getProjectsDict(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = result.data;

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => {
          this.isLoading = false;
          this.close();
        }
      });
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      campaigns: [this.config.data.campaigns.map(x => x.id)],
      project: ['', Validators.required]
    });
  }

  /**
   * Close modal
   *
   * @param {boolean} submit
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Submit copy campaigns
   */
  submit(): void {
    if (this.isLoadingSubmit || !this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;

    this.campaignsService
      .copyCampaign(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingSubmit = false;
          if (result.status === 'OK') {
            this.alerts.success(this.translate.translate('alert_campaignSuccessfullyCopied'), 3000);
            this.close(true);
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
