import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { ProjectsGridComponent } from './components/projects-grid/projects-grid.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { ProjectViewComponent } from './components/project-view/project-view.component';
import { ProjectsDeletedGridComponent } from './components/projects-deleted-grid/projects-deleted-grid.component';
import { DynamicGridModule } from '../../../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { TranslocoModule } from '@jsverse/transloco';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule, TranslocoModule];

const ENTRY_COMPONENTS = [ProjectsGridComponent, ProjectFormComponent, ProjectViewComponent, ProjectsDeletedGridComponent];

@NgModule({
  imports: [...ENTRY_MODULES],
  declarations: [...ENTRY_COMPONENTS]
})
export class ProjectsModule {}
