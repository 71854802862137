import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Creative,
  CreativeData,
  DynamicGridAction,
  DynamicGridColumn,
  FilterChanges,
  FiltersConfig,
  GridRequest,
  MultiChangeStatusResponse
} from '../../../../../../core/interface';
import { CreativesService } from '../../creatives.service';
import { DspService } from '../../../../../../core/services/dsp.service';
import { CopyFormComponent } from '../copy-form/copy-form.component';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { CreativeViewComponent } from '../creative-view/creative-view.component';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { APP_ROUTE, DESK_ROUTE } from '../../../../../../core/routes';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { BreadcrumbsService } from '../../../../../../core/services/breadcrumbs.service';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { MessageService } from '../../../../../../core/services/message.service';
import { ClickUrlFormComponent } from '../../../campaigns/components/click-url-form/click-url-form.component';
import { CreativeEditFormComponent } from '../creative-edit-form/creative-edit-form.component';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';
import { Status } from 'src/app/core/configs';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-creatives-grid',
  templateUrl: './creatives-grid.component.html',
  styleUrls: ['./creatives-grid.component.scss'],
  providers: [FiltersService]
})
export class CreativesGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isLoadingChangeStatus = false;
  public isNotCreated = false;
  public isNotFound = false;
  public readonly Status = Status;
  public columns: DynamicGridColumn[] = [];

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<CreativeData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.creativesService.getCreatives(data))
  );

  constructor(
    public filter: FiltersService,
    private dspService: DspService,
    private creativesService: CreativesService,
    private queryParamsService: QueryParamsService,
    private router: Router,
    private alerts: AdxadAlerts,
    private modal: AdxadModal,
    private sidebarModal: AdxadSidebarModal,
    public roles: RolesService,
    public breadcrumbs: BreadcrumbsService,
    private messageService: MessageService,
    private translate: TranslocoService,
    public globica: GlobicaService
  ) {}

  ngOnInit(): void {
    this.paginator.init();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: CreativeData) => {
        this.isLoading = false;
        this.columns = result.meta?.columns;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.messageService
      .get('reload-creatives-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data.submit) {
          this.loadGrid();
        }
      });

    this.loadFilters();
  }

  /**
   * Load filters list
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('creatives')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            this.subscribeFilter();
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        () => (this.isLoading = false)
      );
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set creatives grid
   * @param {CreativeData} result
   */
  private setGrid(result: CreativeData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * Check actions in grid
   * toggleStatus - active/stop creative
   * openView - open detail creative in sidebar modal
   * editCreative - open edit creative form
   * editClickUrl - open edit click url form
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }
    const creative = action.value as Creative;

    if (action.action === 'toggleStatus') {
      const status = creative.status === this.Status.active ? this.Status.stopped : this.Status.active;
      this.changeStatus([creative], status);
    }

    if (action.action === 'openView') {
      if (!action.value.id) {
        return;
      }
      this.openSidebarModal(action.value.id);
    }

    if (action.action === 'editCreative') {
      if (!action.value) {
        return;
      }
      this.modal.open(CreativeEditFormComponent, {
        data: {
          creative: action.value
        }
      });
    }

    if (action.action === 'editClickUrl') {
      if (!action.value) {
        return;
      }
      this.modal.open(ClickUrlFormComponent, {
        data: action.value
      });
    }
  }

  /**
   * Change status in selected creatives
   *
   * @param {number} status
   */
  multiChangeStatus(status: number): void {
    const creatives = this.dynamicGrid.getChecked();
    const ids = creatives.map(x => x.id);
    let globicaKey;

    switch (status) {
      case this.Status.active: {
        globicaKey = 'buttonActivate_click';
        break;
      }
      case this.Status.stopped: {
        globicaKey = 'buttonStop_click';
        break;
      }
      case this.Status.archived: {
        globicaKey = 'buttonDelete_click';
        break;
      }
    }

    this.globica.trackEventGoals(globicaKey, { objects_type: 'creatives', ids });
    this.changeStatus(creatives, status);
  }

  /**
   * Set new status to creatives
   *
   * @param {Creative[]} creatives
   * @param {number} status
   */
  changeStatus(creatives: Creative[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    let filteredCreatives = creatives;

    if (status !== this.Status.archived) {
      filteredCreatives = creatives.filter(x => x.status !== 2 && x.status !== 6 && x.status !== 10);

      if (filteredCreatives.length < creatives.length) {
        this.alerts.warning(this.translate.translate('alert_creativesOnStatusPendingCannotChangeStatus'), 3000);
      }

      if (!filteredCreatives.length) {
        this.dynamicGrid.resetMultiSelect();
        this.isLoadingChangeStatus = false;
        return;
      }
    }

    const data = {
      ids: filteredCreatives.map(x => x.id),
      status: status
    };

    filteredCreatives.forEach(x => (x.isLoading = true));
    this.creativesService
      .changeCreativesStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (result: MultiChangeStatusResponse) => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data) {
              this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
              filteredCreatives.forEach(x => (x.isLoading = false));
              return;
            }

            filteredCreatives.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                return true;
              }

              if (x.status !== obj.status) {
                let message: string;

                switch (obj.status) {
                  case Status.active: {
                    message = 'alert_creativeIsActivated';
                    break;
                  }
                  case Status.stopped: {
                    message = 'alert_creativeIsStopped';
                    break;
                  }
                  case Status.archived: {
                    message = 'alert_creativeIsDeleted';
                    break;
                  }
                  default: {
                    message = 'alert_creativeIsChanged';
                  }
                }

                this.alerts.success(this.translate.translate(message, { creativeName: x.name }), 3000);
                x.status = obj.status;
              }
            });

            this.dynamicGrid.resetMultiSelect();

            if (status === Status.archived) {
              this.loadGrid();
            }
          }
        },
        () => {
          this.isLoadingChangeStatus = false;
          creatives.forEach(x => (x.isLoading = false));
        }
      );
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  /**
   * @return {boolean} flag to disable actions buttons
   */
  get isDisableActions(): boolean {
    return this.isLoading || this.isLoadingChangeStatus;
  }

  /**
   * Copy selected creatives to campaign
   */
  multiCopyCreatives(): void {
    const creatives = this.dynamicGrid.getChecked();
    const ids = creatives.map(x => x.id);

    this.globica.trackEventGoals('buttonCopy_click', { ids });

    this.modal
      .open(CopyFormComponent, {
        width: '500px',
        data: {
          creatives: this.dynamicGrid.getChecked()
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.loadGrid();
      });
  }

  /**
   * Open detail creative view
   *
   * @param {string} id
   */
  openSidebarModal(id: string): void {
    if (!id || typeof id !== 'string') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.breadcrumbs.reset();
    this.sidebarModal.open(CreativeViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', { filters: this.filter.globicaFilterRequest });
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Go to deleted grid
   * Set filter query params
   */
  goToDeleted(): void {
    const url = APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.deletedCreatives;
    const queryParams = this.queryParamsService.get('f_');

    /** Track globica event */
    this.globica.trackEventGoals('buttonDeletedCreatives_click');
    this.router.navigate([url], { queryParams });
  }

  ngOnDestroy(): void {
    this.messageService.remove('reload-creatives-grid');
  }
}
