<div
  class="new-creatives"
  *ngIf="creatives.length"
  [formGroup]="form"
>
  <header class="new-creatives__header row">
    <div class="col-7">
      <div class="new-creatives__title">{{ 'newCreatives' | transloco }}</div>

      <div class="new-creatives__statuses">
        <span class="new-creatives__status">
          {{ 'notification_successfully' | transloco }}:

          <span class="new-creatives__status-success"> {{ creativesCount('success') }} </span>
        </span>

        <span class="new-creatives__status">
          {{ 'notification_fileErrors' | transloco }}:

          <span class="new-creatives__status-error"> {{ creativesCount('error') }} </span>
        </span>
      </div>
    </div>
    <div class="col-5 text-right">
      <button
        adxadLinkButton
        (click)="clear(true)"
        [disabled]="isLoading"
      >
        {{ 'action_cancel' | transloco }}
      </button>
      <button
        adxadButton
        (click)="submit()"
        [disabled]="isDisableAddBtn()"
      >
        {{ (roles.isManagers() ? 'action_addCreatives' : 'action_submitForModeration') | transloco }}
      </button>
    </div>
  </header>

  <div
    class="loader"
    *ngIf="isLoading"
  >
    <adxad-loader [diameter]="40"></adxad-loader>
  </div>

  <div
    class="row"
    *ngIf="!isLoading"
  >
    <div
      class="col-12 new-creatives__item"
      [ngClass]="{'col-sm-6 col-lg-4' : campaign.mediaType.id !== 'pops' && campaign.mediaType.id !== 'interstitial'}"
      formArrayName="creatives"
      *ngFor="let creative of newCreatives; index as i"
    >
      <adxad-creative-preview
        [control]="getCreative(i)"
        [mediaType]="campaign.mediaType.id"
        [format]="campaign.format"
        [creative]="creative"
        [index]="i"
        (removeCreative)="removeCreative($event)"
        (uploadIcon)="uploadIcon(i)"
      ></adxad-creative-preview>
    </div>
  </div>
</div>
