import { Component } from '@angular/core';
import { DESK_ROUTE } from '../../../core/routes';
import { GlobicaService } from '../../../core/services/globica.service';
import { LayoutService } from '../../../layout/layout.service';

@Component({
  selector: 'app-trade-desk-layout',
  template: `
    <div
      class="adxad-main-container"
      [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
    >
      <header class="adxad-header-section">
        <adxad-tabs-nav>
          <adxad-tab-link
            *ngFor="let tab of tabs"
            [routerLink]="tab.link"
            routerLinkActive="active"
            (click)="globica.trackEventGoals(tab.globicaKey)"
          >
            {{ tab.name | transloco }}
            <adxad-help-icon
              class="tooltip-icon"
              *ngIf="tab.tooltip"
              [adxadTooltip]="tab.tooltip | transloco"
            ></adxad-help-icon>
          </adxad-tab-link>
        </adxad-tabs-nav>
      </header>

      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./trade-desk-layout.component.scss']
})
export class TradeDeskLayoutComponent {
  tabs = [
    {
      name: 'pageTitle_campaigns',
      stage: 'campaigns',
      link: './' + DESK_ROUTE.campaigns,
      globicaKey: 'linkCampaigns_click'
    },
    {
      name: 'pageTitle_creatives',
      stage: 'creatives',
      link: './' + DESK_ROUTE.creatives,
      globicaKey: 'linkCreatives_click'
    },
    {
      name: 'pageTitle_projects',
      stage: 'projects',
      link: './' + DESK_ROUTE.projects,
      tooltip: 'tooltips_tabProjects',
      globicaKey: 'linkProjects_click'
    }
  ];

  constructor(
    public globica: GlobicaService,
    public layoutService: LayoutService
  ) {}
}
