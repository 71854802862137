import { NgModule } from '@angular/core';
import { GuiModule } from '../../gui/gui.module';
import { CampaignsModule } from './modules/campaigns/campaigns.module';
import { ProjectsModule } from './modules/projects/projects.module';
import { CreativesModule } from './modules/creatives/creatives.module';
import { CreativesFormModule } from './modules/creatives-form/creatives-form.module';
import { TradeDeskLayoutComponent } from './layout/trade-desk-layout.component';
import { CampaignFormModule } from './modules/campaign-form/campaign-form.module';
import { RouterModule } from '@angular/router';
import { routes } from './trade-desk.routes';

const TRADE_DESK_MODULES = [ProjectsModule, CampaignsModule, CreativesModule, CreativesFormModule, CampaignFormModule];

@NgModule({
  imports: [GuiModule, ...TRADE_DESK_MODULES, RouterModule.forChild(routes)],
  exports: [...TRADE_DESK_MODULES, RouterModule],
  declarations: [TradeDeskLayoutComponent]
})
export class TradeDeskModule {}
