import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CampaignCreative, CampaignDetail } from '../../../../../../core/interface';
import { CreativesFormService } from '../../creatives-form.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-change-creatives-form',
  templateUrl: './change-creatives-form.component.html',
  styleUrls: ['./change-creatives-form.component.scss']
})
export class ChangeCreativesFormComponent implements OnInit {
  @Input() campaign: CampaignDetail;

  public isLoading = false;
  public form: UntypedFormGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private creativesFormService: CreativesFormService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private globica: GlobicaService,
    public roles: RolesService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  /**
   * @return {string} class name for div
   */
  get itemClassName(): string {
    return this.campaign.mediaType.id !== 'pops' && this.campaign.mediaType.id !== 'interstitial' ? 'col-sm-6 col-lg-4' : 'pops';
  }

  /**
   * Create reactive form
   */
  createForm() {
    this.form = this.fb.group({
      creatives: this.fb.array([])
    });

    if (this.campaign.creatives && this.campaign.creatives.length) {
      this.addCreatives(this.campaign.creatives);
    }
  }

  /**
   * Add creatives in form
   */
  addCreatives(creatives: CampaignCreative[]) {
    creatives.forEach(x => {
      const group = this.fb.group({
        id: x.id,
        name: [x.name || '', Validators.required]
      });

      switch (this.campaign.mediaType.id) {
        case 'html': {
          group.addControl('html', this.fb.control(x.html, Validators.required));
          break;
        }
        case 'pops': {
          group.addControl('clickUrl', this.fb.control(x.clickUrl, Validators.required));
          break;
        }
        case 'interstitial': {
          group.addControl('clickUrl', this.fb.control(x.clickUrl, Validators.required));
          break;
        }
        case 'vast': {
          group.addControl('videoUrl', this.fb.control(x.videoUrl, Validators.required));
          break;
        }
        case 'native': {
          group.addControl('title', this.fb.control(x.title, Validators.required));
          group.addControl('description', this.fb.control(x.description, Validators.required));
          group.addControl('brandName', this.fb.control(x.brandName));
          break;
        }
        case 'push': {
          group.addControl('title', this.fb.control(x.title, Validators.required));
          group.addControl('description', this.fb.control(x.description, Validators.required));
          break;
        }
      }

      this.creatives().push(group);
    });
  }

  /**
   * @return {UntypedFormArray} creatives reactive form array
   */
  creatives(): UntypedFormArray {
    return this.form.get('creatives') as UntypedFormArray;
  }

  /**
   * @return {UntypedFormGroup} creative form group by index
   * @param {number} i
   */
  getCreative(i: number): UntypedFormGroup {
    return this.form.get(['creatives', i]) as UntypedFormGroup;
  }

  /**
   * Count creatives by status
   * statuses:
   * paused: 0 - show as Approved
   * active: 1 - show as Approved
   * pending: 2 - show as Pending
   * rejected: 3 - show as Rejected
   * on moderation: 6 - show as Pending
   * awaiting update click url of campaign: 8 - show as Approved
   *
   * @param {string} status
   */
  creativesCount(status: string): number {
    const creatives = this.campaign.creatives;

    switch (status) {
      case 'approved': {
        return creatives.filter(x => [0, 1, 8].indexOf(x.status) !== -1).length;
      }
      case 'pending': {
        return creatives.filter(x => [2, 6].indexOf(x.status) !== -1).length;
      }
      case 'rejected': {
        return creatives.filter(x => x.status === 3).length;
      }
    }
  }

  /**
   * @return {boolean} disable save changes btn flag
   */
  isDisableSaveBtn(): boolean {
    return this.isLoading || !this.form.dirty || this.form.invalid;
  }

  /**
   * @return {boolean} disable cancel changes btn flag
   */
  isDisableCancelBtn(): boolean {
    return this.isLoading || !this.form.dirty;
  }

  /**
   * Reset values in form
   */
  cancelChanges(): void {
    this.creatives().patchValue(this.campaign.creatives);
    this.form.markAsPristine();
    /** Track globica event */
    this.globica.trackEventGoals('buttonCancel_click');
  }

  /**
   * Save changes
   */
  submit(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.globica.trackEventGoals('buttonSaveChanges_click');

    this.creativesFormService
      .editCreatives(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ status, data }) => {
          this.isLoading = false;
          if (status === 'OK') {
            this.campaign.creatives = data;
            this.alerts.success(this.translate.translate('alert_creativesSuccessfullyChanged'), 3000);
            this.form.markAsPristine();
          }
        },
        error: () => (this.isLoading = false)
      });
  }
}
