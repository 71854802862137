<adxad-loader
  *ngIf="isLoading"
  [diameter]="40"
  class="loader"
></adxad-loader>

<div
  class="dnd-area"
  *ngIf="isDndAreaShow"
  (dragleave)="dragLeave()"
  (drop)="drop($event)"
>
  <img
    src="/assets/images/dnd/icon.svg"
    alt="{{ 'notification_dropYourFilesToUpload' | transloco }}"
  />
  <p>{{ 'notification_dropYourFilesToUpload' | transloco }}</p>
</div>

<div
  class="row creatives-form no-gutters"
  *ngIf="!isLoading && campaign"
>
  <div class="col-12 col-xl-3 creatives-form__sidebar">
    <h2>
      <span
        class="material-icons pointer"
        (click)="back(true)"
      >
        arrow_back_ios
      </span>

      {{ 'addCreatives' | transloco }}
    </h2>

    <div class="campaign-info">
      <div class="campaign-info__block">
        <adxad-label> {{ 'label_campaignName' | transloco }} </adxad-label>

        <div class="campaign-info__block-value">{{ campaign.name }}</div>
      </div>

      <div
        class="campaign-info__block"
        *ngIf="isImageForm"
      >
        <adxad-label> {{ 'label_clickUrl' | transloco }} </adxad-label>

        <div class="campaign-info__block-value">
          <app-cut-url [url]="campaign.clickUrl"></app-cut-url>
        </div>
      </div>

      <div class="campaign-info__block">
        <adxad-label> {{ 'label_format' | transloco }} </adxad-label>

        <div class="campaign-info__block-value">
          {{ format }}

          <adxad-info-block
            *ngIf="isPush || isNative"
            [isShowIcon]="false"
            class="campaign-info__block-info"
            type="info"
          >
            <ng-template [ngTemplateOutlet]="isPush ? pushHelp : nativeHelp"></ng-template>

            <ng-template #pushHelp>
              {{ 'imageSize' | transloco: {size: '428 x 328'} }} <br />
              {{ 'iconSize' | transloco: {size: '192 x 192'} }}
            </ng-template>

            <ng-template #nativeHelp>
              {{ 'aspectRatioMustBe' | transloco: {ratio: '4 x 3'} }}
              <br />
              {{ 'maxImageSize' | transloco: {size: '1500 x 1125'} }}
            </ng-template>
          </adxad-info-block>
        </div>
      </div>

      <div
        class="campaign-info__block"
        *ngIf="isPopsType"
      >
        <adxad-label> {{ 'campaignBid' | transloco }}, ({{ campaign.costModel | uppercase }}) </adxad-label>

        <div class="campaign-info__block-value">{{ campaign.bid?.value }}</div>
      </div>
    </div>

    <section
      *ngIf="isImageForm"
      class="row"
    >
      <div class="col-6 col-xl-12 upload-section">
        <div class="upload-section__title creatives-form__section-title">{{ 'maximumSize' | transloco: {size: maxSize} }}</div>

        <button
          adxadButton
          class="upload-section__btn"
          (click)="addFiles()"
        >
          {{ 'action_uploadFromDisc' | transloco }}
        </button>

        <div class="upload-section__dnd">
          <span class="material-icons upload-section__dnd-icon"> cloud_download_outline </span>
          <br />
          <span class="upload-section__dnd-title"> {{ 'notification_dragAndDropFiles' | transloco }} </span>
        </div>
      </div>

      <div class="col-6 col-xl-12 add-by-url">
        <adxad-label> {{ 'label_addFilesByUrl' | transloco }} </adxad-label>

        <adxad-form-field>
          <adxad-input
            placeholder="{{ 'placeholder_pasteFileUrlHere' | transloco }}"
            class="add-by-url__input"
            [(ngModel)]="uploadUrl"
          ></adxad-input>
        </adxad-form-field>

        <button
          adxadButton
          [disabled]="isDisabledUrlBtn()"
          class="add-by-url__btn"
          (click)="uploadFromUrl()"
        >
          {{ 'action_uploadFromUrl' | transloco }}
        </button>
      </div>
    </section>

    <section
      *ngIf="!isImageForm"
      class="row"
      [formGroup]="iframeForm"
    >
      <div class="col-6 col-xl-12 add-by-url">
        <h4>{{ title }}</h4>

        <adxad-form-field class="iframe__fields">
          <adxad-input
            placeholder="{{ 'placeholder_name' | transloco }}"
            formControlName="name"
            label="{{ 'label_name' | transloco }}"
            [autofocus]="true"
          ></adxad-input>
          <app-show-errors [control]="iframeForm.get('name')"></app-show-errors>
        </adxad-form-field>

        <adxad-tabs-nav
          class="iframe-tabs"
          *ngIf="campaign.mediaType.id === 'html'"
        >
          <adxad-tab-link
            [class.active]="showTab === 1"
            (click)="changeTab(1)"
          >
            HTML
          </adxad-tab-link>

          <adxad-tab-link
            [disabled]="!iframeForm.get('code').value.length"
            [class.active]="showTab === 2"
            (click)="changeTab(2)"
          >
            {{ 'preview' | transloco }}
          </adxad-tab-link>
        </adxad-tabs-nav>

        <ng-container *ngIf="showTab === 1">
          <adxad-form-field>
            <adxad-textarea
              class="iframe__input"
              [placeholder]="placeholder"
              formControlName="code"
              (blurEvent)="setCursorPosition($event)"
              [fixWidth]="true"
              label="{{ 'label_enterUrl' | transloco }}"
            ></adxad-textarea>
          </adxad-form-field>

          <adxad-holders
            class="creatives-form__holders"
            [holders]="dict.holders.data"
            [text]="iframeForm.get('code').value"
            (changeHolder)="toggleHolder($event)"
            label="{{ 'label_holders' | transloco }}"
            *ngIf="!isVast"
          ></adxad-holders>
        </ng-container>

        <div
          class="html-preview"
          *ngIf="showTab === 2 && campaign.mediaType.id === 'html'"
        >
          <div class="html-preview__wrapper">
            <iframe
              [srcdoc]="iframeForm.get('code').value | escapeHtml"
              sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
              class="html-preview__iframe"
            ></iframe>
          </div>
        </div>

        <div
          class="creatives-form__invalid-url"
          *ngIf="isInvalidUrl"
        >
          {{ 'notification_thisUrlIsInvalid' | transloco }}
        </div>

        <button
          adxadButton
          class="add-by-url__btn"
          [disabled]="isDisabledNonImageAddBtn"
          (click)="addNonImageCreative()"
        >
          {{ 'action_addCreative' | transloco }}
        </button>
      </div>
    </section>
  </div>

  <div class="col-12 col-xl-9 creatives-form__body">
    <adxad-new-creatives-form
      [campaign]="campaign"
      (created)="addNewCreatives($event)"
    ></adxad-new-creatives-form>

    <adxad-change-creatives-form [campaign]="campaign"></adxad-change-creatives-form>
  </div>
</div>
