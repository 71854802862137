import { Component, OnInit } from '@angular/core';
import { AdxadModalConfig } from '../../../../../../ui/modules/modal/modal-config';
import { AdxadModalRef } from '../../../../../../ui/modules/modal/modal-ref';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CreativeFormEl } from '../../../../../../core/interface';
import { BehaviorSubject } from 'rxjs';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import { MessageService } from '../../../../../../core/services/message.service';
import { GlobicaService } from '../../../../../../core/services/globica.service';

@Component({
  selector: 'adxad-resize-creative-form',
  templateUrl: './resize-creative-form.component.html',
  styleUrls: ['./resize-creative-form.component.scss']
})
export class ResizeCreativeFormComponent implements OnInit {
  //Data
  public isLoading = false;
  public creative: CreativeFormEl;
  public mediaType: string = '';

  //Cropper vars
  private croppedNativeImage: any = '';
  public croppedImage: any = '';
  public canvasRotation = 0;
  public rotation = 0;
  public scale = 1;
  public transform: ImageTransform = {};

  public cropperMaxWidth: number = 0;
  public cropperMaxHeight: number = 0;
  public cropperMinWidth: number = 0;
  public cropperMinHeight: number = 0;
  public resizeToHeight: number = 0;
  public resizeToWidth: number = 0;
  public cropperStaticWidth: number = 0;
  public cropperStaticHeight: number = 0;
  public format: OutputFormat = 'jpeg';
  public maintainAspectRatio: boolean = false;
  public aspectRatio = null;
  public hideResizeSquares = true;
  public showCropper = false;

  public cropImageFileSize$ = new BehaviorSubject(null);
  public cropImageWidth$ = new BehaviorSubject<number>(0);
  public cropImageHeight$ = new BehaviorSubject<number>(0);

  constructor(
    public config: AdxadModalConfig,
    private modalRef: AdxadModalRef,
    private messageService: MessageService,
    private globica: GlobicaService
  ) {}

  ngOnInit(): void {
    const data = this.config.data;

    if (!data || !data.creative || !data.mediaType || !data.format || !data.fileName) {
      this.close();
      return;
    }

    this.initCropper();
  }

  get imageSize(): string {
    return `${this.cropImageWidth$.value}x${this.cropImageHeight$.value}px`;
  }

  /**
   * Init resizer
   */
  initCropper(): void {
    this.creative = this.config.data.creative;

    this.mediaType = this.config.data.mediaType;
    this.format = this.creative.data.type.split('/')[1] as OutputFormat;

    switch (this.config.data.mediaType) {
      case 'image': {
        [this.cropperMaxWidth, this.cropperMaxHeight] = this.config.data.format.split('x').map(Number);
        [this.resizeToWidth, this.resizeToHeight] = [this.cropperMaxWidth, this.cropperMaxHeight];
        break;
      }
      case 'native': {
        this.cropperMaxWidth = 1500;
        this.cropperMaxHeight = 1125;

        this.cropperMinWidth = 400;
        this.cropperMinHeight = 300;

        this.maintainAspectRatio = true;
        this.hideResizeSquares = false;
        this.aspectRatio = 4 / 3;
        break;
      }
      case 'push': {
        this.cropperMaxWidth = 428;
        this.cropperMaxHeight = 328;
        [this.resizeToWidth, this.resizeToHeight] = [this.cropperMaxWidth, this.cropperMaxHeight];
        break;
      }
    }
  }

  /**
   * Return true if file size bigger than 1 mb
   */
  isInvalidFileSize(): boolean {
    return this.cropImageFileSize$.value > 1000000;
  }

  /**
   * Check aspect ratio of image
   *
   * @param w
   * @param h
   */
  checkRatio(w, h): string {
    function gcd(a, b) {
      return b === 0 ? a : gcd(b, a % b);
    }

    const r = gcd(w, h);
    return w / r + 'x' + h / r;
  }

  /**
   * Rotate left image by click
   */
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  /**
   * Rotate right image by click
   */
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  /**
   * Zoom out image by click
   */
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  /**
   * Zoom in image by click
   */
  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  /**
   * Reset image transform by click
   */
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  /**
   * Emits an ImageCroppedEvent each time the image is cropped
   * @param event
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.cropImageWidth$.next(event.width);
    this.cropImageHeight$.next(event.height);
    this.cropImageFileSize$.next(base64ToFile(event.base64).size);

    if (this.mediaType === 'native') {
      this.checkRatioAndFixImage();
    }
  }

  /**
   * Fixing the aspect ratio for an image
   */
  checkRatioAndFixImage() {
    this.croppedNativeImage = '';

    const aspectRatio = this.checkRatio(this.cropImageWidth$.value, this.cropImageHeight$.value);

    if (aspectRatio !== '4x3') {
      /**
       * Checking division without a remainder
       */
      let xWidth = this.cropImageWidth$.value % 4;

      /**
       * Calculate the number that must be added to the width so that the width is a multiple of 4.
       */
      if (xWidth !== 0) {
        xWidth = xWidth > 1 ? 4 - xWidth : -1;
      }

      /**
       * Set new width, height
       */
      let width = this.cropImageWidth$.value + xWidth;
      let height = width / (4 / 3);

      /**
       * Resize image
       */
      let img = new Image();
      img.src = this.croppedImage;

      img.onload = () => {
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        this.croppedNativeImage = canvas.toDataURL(this.creative.data.type);
      };
    }
  }

  /**
   * Detect laded image and show cropper
   */
  imageLoaded() {
    this.showCropper = true;
  }

  /**
   * Update image coordinates
   * @private
   */
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  /**
   * Submit form
   */
  submit(): void {
    /** Track globica event */
    this.globica.trackEventGoals('buttonCreativeResizeSave_click');

    fetch(this.croppedNativeImage.length > 0 ? this.croppedNativeImage : this.croppedImage)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], this.config.data.fileName, { type: this.creative.data.type }))
      .then(file => {
        this.messageService.add('resizeComplete', { submit: true, value: file });
        this.close(true);
      });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   * @param file
   */
  close(submit?: boolean, file?: File): void {
    this.modalRef.close({ submit, file });
  }
}
