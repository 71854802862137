<div class="adxad-grid-header">
  <span
    class="material-icons pointer"
    (click)="goBack()"
  >
    arrow_back_ios
  </span>
  <div class="adxad-subtitle">{{ 'deletedCampaigns' | transloco }}</div>
</div>

<section class="adxad-toolbar">
  <div class="adxad-actions">
    <button
      adxadStrokedButton
      class="w100"
      [disabled]="isDisableMultiActions()"
      (click)="multiRestore()"
      icon="refresh"
    >
      {{ 'action_restore' | transloco }}
    </button>
  </div>
</section>

<section class="filter-toolbar">
  <adxad-calendar
    (changeDates)="filter.changeCalendarDates($event)"
    [from]="filter.calendar?.value?.calendar.from"
    [to]="filter.calendar?.value?.calendar.to"
  ></adxad-calendar>

  <adxad-filter-actions
    [count]="filter.count"
    [notApplied]="filter.isNotApplied"
    (openFilter)="openFilter()"
    (clearFilters)="clearFilters()"
    (applyFilter)="applyFilter()"
  ></adxad-filter-actions>
</section>

<section
  class="adxad-grid"
  [ngClass]="{'hide': isNotFound}"
>
  <adxad-dynamic-grid
    (action)="gridAction($event)"
    (reload)="loadGrid()"
    [loading]="isLoading"
  ></adxad-dynamic-grid>

  <adxad-paginator (changePage)="loadGrid()"></adxad-paginator>
</section>

<adxad-grid-not-found
  *ngIf="isNotFound"
  (clearFilters)="clearFilters()"
></adxad-grid-not-found>
