import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Campaign,
  CampaignData,
  DynamicGridAction,
  DynamicGridColumn,
  FilterChanges,
  FiltersConfig,
  GridRequest
} from '../../../../../../core/interface';
import { CampaignsService } from '../../campaigns.service';
import { DspService } from '../../../../../../core/services/dsp.service';
import { CopyFormComponent } from '../copy-form/copy-form.component';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { CampaignViewComponent } from '../campaign-view/campaign-view.component';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Router } from '@angular/router';
import { APP_ROUTE, DESK_ROUTE } from '../../../../../../core/routes';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { BreadcrumbsService } from '../../../../../../core/services/breadcrumbs.service';
import { ClickUrlFormComponent } from '../click-url-form/click-url-form.component';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { MessageService } from '../../../../../../core/services/message.service';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Status } from '../../../../../../core/configs';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-campaigns-grid',
  templateUrl: './campaigns-grid.component.html',
  styleUrls: ['./campaigns-grid.component.scss'],
  providers: [FiltersService]
})
export class CampaignsGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true }) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isLoadingFilters = false;
  public isNotCreated = false;
  public isNotFound = false;
  public readonly Status = Status;
  public columns: DynamicGridColumn[] = [];

  private isLoadingChangeStatus = false;
  private destroyRef = inject(DestroyRef);

  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<CampaignData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.campaignsService.getCampaigns(data))
  );

  constructor(
    public filter: FiltersService,
    public globica: GlobicaService,
    private dspService: DspService,
    private campaignsService: CampaignsService,
    private breadcrumbs: BreadcrumbsService,
    private queryParamsService: QueryParamsService,
    private alerts: AdxadAlerts,
    private modal: AdxadModal,
    private sidebarModal: AdxadSidebarModal,
    private router: Router,
    public roles: RolesService,
    private messageService: MessageService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.paginator.init();
    this.subscribeFilter();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: CampaignData) => {
        this.isLoading = false;
        this.columns = result.meta?.columns;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.messageService
      .get('reload-campaigns-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          if (data.submit) {
            this.loadGrid();
          }
        }
      });

    this.loadFilters();
  }

  /**
   * Load filters list
   */
  loadFilters(): void {
    this.isLoadingFilters = true;

    this.dspService
      .getFilters('campaigns')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoadingFilters = false;

          if (status === 'OK') {
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        error: () => (this.isLoadingFilters = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Check actions in grid
   * toggleStatus - active/stop campaign
   * openView - open detail campaign in sidebar modal
   * editClickUrl - open edit click url form
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }
    const campaign = action.value as Campaign;

    switch (action.action) {
      case 'toggleStatus': {
        let status = this.Status.active;
        if (
          campaign.status === this.Status.active ||
          campaign.status === this.Status.pausedByDB ||
          campaign.status === this.Status.rejectedByTB
        ) {
          status = this.Status.stopped;
        }
        this.changeStatus([campaign], status);
        break;
      }
      case 'openView': {
        if (!action.value.id) {
          return;
        }
        this.openSidebarModal(action.value.id);
        break;
      }
      case 'editClickUrl': {
        if (!action.value) {
          return;
        }
        this.modal.open(ClickUrlFormComponent, {
          data: action.value
        });

        break;
      }
      case 'openSubGrid': {
        if (!campaign.creatives) {
          return;
        }
        this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.creatives], {
          queryParams: { f_campaign: '[{"id":"' + campaign.id + '","value":"' + campaign.name + '"}]' }
        });
      }
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  private resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * Change status in selected campaigns
   *
   * @param {number} status
   */
  multiChangeStatus(status: number): void {
    const campaigns = this.dynamicGrid.getChecked();
    const ids = campaigns.map(x => x.id);
    let globicaKey;

    switch (status) {
      case this.Status.active: {
        globicaKey = 'buttonActivate_click';
        break;
      }
      case this.Status.stopped: {
        globicaKey = 'buttonStop_click';
        break;
      }
      case this.Status.archived: {
        globicaKey = 'buttonDelete_click';
        break;
      }
    }

    this.globica.trackEventGoals(globicaKey, { objects_type: 'campaigns', ids });

    this.changeStatus(campaigns, status);
  }

  /**
   * Set new status to campaigns
   *
   * @param campaigns
   * @param status
   */
  changeStatus(campaigns: Campaign[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: campaigns.map(x => x.id),
      status: status
    };

    campaigns.forEach(x => (x.isLoading = true));
    this.campaignsService
      .changeCampaignsStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data) {
              this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
              campaigns.forEach(x => (x.isLoading = false));
              return;
            }

            campaigns.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                return true;
              }

              if (x.status !== obj.status) {
                let message;
                let globicaKey;

                switch (obj.status) {
                  case Status.active: {
                    message = 'alert_campaignIsActivated';
                    globicaKey = 'alertActivated_show';
                    break;
                  }
                  case Status.stopped: {
                    message = 'alert_campaignIsStopped';
                    globicaKey = 'alertStopped_show';
                    break;
                  }
                  case Status.archived: {
                    message = 'alert_campaignIsDeleted';
                    globicaKey = 'alertDeleted_show';
                    break;
                  }
                  default: {
                    message = 'alert_campaignIsChanged';
                  }
                }

                this.globica.trackEventGoals(globicaKey, { ids: data.ids });

                this.alerts.success(this.translate.translate(message, { campaignName: x.name }), 3000);

                x.status = obj.status;
              }
            });

            this.dynamicGrid.resetMultiSelect();

            if (status === Status.archived) {
              this.loadGrid();
            }
          }
        },
        error: () => {
          this.isLoadingChangeStatus = false;
          campaigns.forEach(x => (x.isLoading = false));
        }
      });
  }

  /**
   * Copy selected campaigns in project
   */
  multiCopyCampaign(): void {
    const campaigns = this.dynamicGrid.getChecked();
    const ids = campaigns.map(x => x.id);

    this.globica.trackEventGoals('buttonCopy_click', { ids });

    this.modal
      .open(CopyFormComponent, {
        width: '560px',
        data: {
          campaigns: this.dynamicGrid.getChecked()
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadGrid();
        }
      });
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  get isDisableMultiActions(): boolean {
    return this.isLoadingChangeStatus || !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  /**
   * Set custom commission in selected campaigns
   *
   * @param customCommission
   */
  multiSetCustomCommission(customCommission: number): void {
    const campaigns = this.dynamicGrid.getChecked() as Campaign[];
    const ids = campaigns.map(x => x.id);
    this.globica.trackEventGoals('buttonCustomCommissionSet_click', { ids, customCommission });

    campaigns.forEach(x => (x.isLoading = true));

    this.campaignsService
      .changeCampaignsCustomCommission({ ids, customCommission })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          if (result.status === 'OK') {
            campaigns.forEach(campaign => (campaign.isLoading = false));

            const campaignsMap = new Map(ids.map(id => [id, campaigns.find(c => c.id === id)]));

            const showAlert = (idList: string[], isValid: boolean) => {
              idList.forEach(id => {
                const campaign = campaignsMap.get(id);
                if (campaign) {
                  const message = `Commission ${isValid ? 'changed' : 'not changed'} for ${campaign.name}`;
                  this.alerts[isValid ? 'success' : 'error'](message, 5000);
                }
              });
            };

            showAlert(result['validIds'], true);
            showAlert(result['invalidIds'], false);

            this.dynamicGrid.resetMultiSelect();
          }
        },
        error: () => campaigns.forEach(x => (x.isLoading = false))
      });
  }

  /**
   * Set bid in selected campaigns
   *
   * @param bid
   */
  multiSetBid(bid: string): void {
    const campaigns = this.dynamicGrid.getChecked() as Campaign[];
    const data = {
      ids: campaigns.map(x => x.id),
      bid: bid
    };
    this.globica.trackEventGoals('buttonBidSet_click', { ids: data.ids, bid: data.bid });

    campaigns.forEach(x => (x.isLoading = true));
    this.campaignsService
      .changeCampaignsBid(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          if (result.status === 'OK') {
            campaigns.forEach(x => {
              x.isLoading = false;
            });
            this.alerts.success(this.translate.translate('alert_bidWasChanged'), 3000);
            this.dynamicGrid.resetMultiSelect();
          }
        },
        error: () => campaigns.forEach(x => (x.isLoading = false))
      });
  }

  /**
   * Navigate to create form
   */
  openForm(): void {
    this.globica.trackEventGoals('buttonCreateCampaign_click');
    this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.campaignForm]);
  }

  /**
   * Open detail campaign view
   *
   * @param {string} id
   */
  openSidebarModal(id: string): void {
    if (!id || typeof id !== 'string') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.breadcrumbs.reset();
    this.sidebarModal.open(CampaignViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', { filters: this.filter.globicaFilterRequest });
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Go to deleted grid
   * Set filter query params
   */
  goToDeleted(): void {
    const url = APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.deletedCampaigns;
    const queryParams = this.queryParamsService.get('f_');

    /** Track globica event */
    this.globica.trackEventGoals('buttonDeletedCampaigns_click');
    this.router.navigate([url], { queryParams });
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Set campaigns grid
   * @param {CampaignData} result
   */
  private setGrid(result: CampaignData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }

  ngOnDestroy(): void {
    this.messageService.remove('reload-campaigns-grid');
  }
}
