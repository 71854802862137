import { Routes } from '@angular/router';
import { ProjectsGridComponent } from './modules/projects/components/projects-grid/projects-grid.component';
import { CampaignsGridComponent } from './modules/campaigns/components/campaigns-grid/campaigns-grid.component';
import { CreativesGridComponent } from './modules/creatives/components/creatives-grid/creatives-grid.component';
import { TradeDeskLayoutComponent } from './layout/trade-desk-layout.component';
import { CampaignFormComponent } from './modules/campaign-form/components/campaign-form/campaign-form.component';
import { MassAddCreativesComponent } from './modules/creatives-form/components/mass-add-creatives/mass-add-creatives.component';

import { DESK_ROUTE } from '../../core/routes';
import { MainGuard } from '../../core/guard/main.guard';
import { CreativesFormGuard } from '../../core/guard/creatives-form.guard';
import { ProjectsDeletedGridComponent } from './modules/projects/components/projects-deleted-grid/projects-deleted-grid.component';
import { CampaignsDeletedGridComponent } from './modules/campaigns/components/campaigns-deleted-grid/campaigns-deleted-grid.component';
import { CreativesDeletedGridComponent } from './modules/creatives/components/creatives-deleted-grid/creatives-deleted-grid.component';
import { RolesGuard } from '../../core/guard/roles.guard';

export const routes: Routes = [
  {
    path: '',
    component: TradeDeskLayoutComponent,
    canActivateChild: [MainGuard, RolesGuard],
    children: [
      {
        path: '',
        redirectTo: DESK_ROUTE.campaigns,
        pathMatch: 'full'
      },
      {
        path: DESK_ROUTE.projects,
        component: ProjectsGridComponent,
        title: 'Trade desk • Projects',
        data: {
          pageType: 'PROJECTS_GRID'
        }
      },
      {
        path: DESK_ROUTE.deletedProjects,
        component: ProjectsDeletedGridComponent,
        title: 'Trade desk • Deleted projects',
        data: {
          pageType: 'PROJECTS_DELETED'
        }
      },
      {
        path: DESK_ROUTE.campaigns,
        component: CampaignsGridComponent,
        title: 'Trade desk • Campaigns',
        data: {
          pageType: 'CAMPAIGNS_GRID'
        }
      },
      {
        path: DESK_ROUTE.deletedCampaigns,
        component: CampaignsDeletedGridComponent,
        title: 'Trade desk • Deleted campaigns',
        data: {
          pageType: 'CAMPAIGNS_DELETED'
        }
      },
      {
        path: DESK_ROUTE.creatives,
        component: CreativesGridComponent,
        data: {
          title: 'Trade desk • Creatives',
          pageType: 'CREATIVES_GRID'
        }
      },
      {
        path: DESK_ROUTE.deletedCreatives,
        component: CreativesDeletedGridComponent,
        title: 'Trade desk • Deleted creatives',
        data: {
          pageType: 'CREATIVES_DELETED'
        }
      }
    ]
  },
  {
    path: DESK_ROUTE.campaignForm,
    component: CampaignFormComponent,
    title: 'Campaign',
    data: {
      pageType: 'CAMPAIGN'
    }
  },
  {
    path: DESK_ROUTE.creativeForm,
    canDeactivate: [CreativesFormGuard],
    component: MassAddCreativesComponent,
    title: 'Creative',
    data: {
      pageType: 'ADD_CREATIVES'
    }
  }
];
