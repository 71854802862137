import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CreativeFormEl } from '../../../../../../core/interface';
import { Status } from '../../../../../../core/configs';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { AdxadModal } from '../../../../../../ui/modules/modal/modal.service';
import { ResizeCreativeFormComponent } from '../resize-creative-form/resize-creative-form.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-creative-preview',
  templateUrl: './creative-preview.component.html',
  styleUrls: ['./creative-preview.component.scss']
})
export class CreativePreviewComponent implements OnInit {
  @Input()
  mediaType: string;

  @Input()
  format: string;

  @Input()
  creative: CreativeFormEl;

  @Input()
  index;

  @Input()
  control: UntypedFormGroup;

  @Output()
  removeCreative = new EventEmitter();

  @Output()
  uploadIcon = new EventEmitter();

  public isNewCreative = false;
  public showTab = 1;
  private destroyRef = inject(DestroyRef);

  constructor(
    private globica: GlobicaService,
    private modal: AdxadModal
  ) {}

  /**
   * @return {boolean} flag of showing status label
   */
  get isShowStatus(): boolean {
    return !this.isNewCreative && this.mediaType !== 'html';
  }

  ngOnInit(): void {
    this.isNewCreative = !this.creative.id;

    if (this.creative.status === Status.pending || this.creative.status === Status.onModeration) {
      this.control.disable();
    }
  }

  /**
   * @return {boolean} flag if is pops or interstitial media type
   */
  get isClickUrlType(): boolean {
    return this.mediaType === 'pops' || this.mediaType === 'interstitial';
  }

  /**
   * Delete creative
   *
   * @param {number} i
   * @param iconClick - clicked element, need this for globica
   */
  deleteEl(i: number, iconClick?: boolean): void {
    /** Track globica event */
    this.globica.trackEventGoals(iconClick ? 'buttonDeleteCreative_click' : 'buttonDeleteWrongCreative_click');
    this.removeCreative.emit(i);
  }

  /**
   * paused: 0 - show as Approved
   * active: 1 - show as Approved
   * pending: 2 - show as Pending
   * rejected: 3 - show as Rejected
   * on moderation: 6 - show as Pending
   * awaiting update click url of campaign: 8 - show as Approved
   *
   * @return {string} status name
   */
  get status(): string {
    let status = 'approved';

    if (this.creative.status === Status.pending || this.creative.status === Status.onModeration) {
      status = 'pending';
    }

    if (this.creative.status === Status.rejected) {
      status = 'rejected';
    }

    return status;
  }

  /**
   * Change active tab
   * @param tab
   */
  changeTab(tab: number): void {
    this.showTab = tab;

    /** Track globica event */
    this.globica.trackEventGoals(tab === 1 ? 'buttonPreview_click' : 'buttonHtml_click');
  }

  /**
   * Open image cropper modal
   */
  openImageCropperForm(): void {
    /** Track globica event */
    this.globica.trackEventGoals('buttonCreativeResize_click');

    this.modal
      .open(ResizeCreativeFormComponent, {
        width: '1100px',
        data: {
          format: this.format,
          creative: this.creative,
          mediaType: this.mediaType,
          fileName: this.creative.name
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ submit, file }) => {
          if (submit) {
            this.creative.data = file;
            delete this.creative.error;
            this.deleteEl(this.index);
          }
        }
      });
  }
}
