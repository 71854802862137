import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { APP_ROUTE, DESK_ROUTE, EXCHANGE_ROUTE, HELP_ROUTE, MODERATION_ROUTE, SETTINGS_ROUTE, USERS_ROUTE } from '../routes';
import { ROLES } from '../configs';
import { filter, map } from 'rxjs/operators';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard {
  navRules = [];

  /**
   * @return filtered roles list
   *
   * @param roles
   */
  getFilteredRoles(roles: string | string[]): string[] {
    return Object.values(ROLES).filter(role => !roles.includes(role));
  }

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {
    this.navRules = [
      {
        route: APP_ROUTE.tradeDesk,
        availableForRoles: this.getFilteredRoles(ROLES.reporter),
        children: [
          {
            route: DESK_ROUTE.campaigns
          },
          {
            route: DESK_ROUTE.creatives
          },
          {
            route: DESK_ROUTE.projects
          }
        ]
      },
      {
        route: APP_ROUTE.reports
      },
      {
        route: APP_ROUTE.moderation,
        availableForRoles: [ROLES.accountManager, ROLES.salesManager, ROLES.admin, ROLES.superAdmin],
        children: [
          {
            route: MODERATION_ROUTE.pending
          },
          {
            route: MODERATION_ROUTE.checking
          },
          {
            route: MODERATION_ROUTE.approved
          },
          {
            route: MODERATION_ROUTE.rejected
          },
          {
            route: MODERATION_ROUTE.needInfo
          }
        ]
      },
      {
        route: APP_ROUTE.payments
      },
      {
        route: APP_ROUTE.users,
        availableForRoles: [ROLES.accountManager, ROLES.salesManager, ROLES.admin, ROLES.superAdmin],
        children: [
          {
            route: USERS_ROUTE.list
          },
          {
            route: USERS_ROUTE.teams
          }
        ]
      },
      {
        route: APP_ROUTE.exchange,
        availableForRoles: [ROLES.admin, ROLES.superAdmin],
        children: [
          {
            route: EXCHANGE_ROUTE.ssps
          },
          {
            route: EXCHANGE_ROUTE.dsps
          },
          {
            route: EXCHANGE_ROUTE.report
          },
          {
            route: EXCHANGE_ROUTE.qualityReport
          }
        ]
      },
      {
        route: APP_ROUTE.settings,
        availableForRoles: [ROLES.accountManager, ROLES.salesManager, ROLES.admin, ROLES.superAdmin],
        children: [
          {
            availableForRoles: [ROLES.admin, ROLES.superAdmin],
            route: SETTINGS_ROUTE.ssps
          },
          {
            availableForRoles: [ROLES.superAdmin],
            route: SETTINGS_ROUTE.segments
          },
          {
            availableForRoles: [ROLES.admin, ROLES.superAdmin],
            route: SETTINGS_ROUTE.events
          },
          {
            route: SETTINGS_ROUTE.translations
          },
          {
            availableForRoles: [ROLES.superAdmin],
            route: SETTINGS_ROUTE.spots
          },
          {
            route: SETTINGS_ROUTE.tokenTool
          }
        ]
      },
      {
        route: APP_ROUTE.help,
        availableForRoles: this.getFilteredRoles(ROLES.reporter),
        children: [
          {
            route: HELP_ROUTE.tradingDeskHelp
          },
          {
            route: HELP_ROUTE.forAdvertisersHelp
          },
          {
            route: HELP_ROUTE.conversionsImportService
          }
        ]
      },
      {
        route: APP_ROUTE.api
      }
    ];
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.profileService.profile$.pipe(
      filter(profile => !!profile),
      map(profile => {
        const url = state.url.split('?')[0].split('/').filter(String);

        /** check section */
        const section = this.navRules.find(x => x.route === url[0]);
        let isAvailable = !section?.availableForRoles || section.availableForRoles.includes(profile.basic.roles.id);

        if (isAvailable && url.length > 1 && section.children) {
          /** check child */
          const child = section.children.find(x => x.route === url[1]);
          isAvailable = !child?.availableForRoles || child.availableForRoles.includes(profile.basic.roles.id);
        }

        return isAvailable || this.router.createUrlTree([profile.baseUrl]);
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
