<div
  class="creative"
  [class.creative_pops]="isClickUrlType"
  [formGroup]="control"
>
  <adxad-loader
    class="creative__loader"
    [diameter]="30"
    *ngIf="creative.isLoading"
  ></adxad-loader>

  <ng-container *ngIf="!creative.isLoading">
    <ng-container *ngIf="!creative.error">
      <!-- pops & interstitial -->
      <ng-container *ngIf="isClickUrlType">
        <div class="row creative_pops__row">
          <adxad-form-field class="col-3">
            <adxad-input
              placeholder="{{ 'placeholder_name' | transloco }}"
              formControlName="name"
            ></adxad-input>
          </adxad-form-field>

          <adxad-form-field class="col">
            <adxad-input
              placeholder="{{ 'placeholder_clickUrl' | transloco }}"
              formControlName="clickUrl"
            ></adxad-input>
          </adxad-form-field>

          <div
            class="col-1"
            *ngIf="isShowStatus"
          >
            <div
              class="creative__status creative__status_pops"
              [ngClass]="status"
            >
              {{ status }}
            </div>
          </div>

          <div
            class="col-auto"
            *ngIf="isNewCreative"
          >
            <button
              (click)="deleteEl(index, true)"
              adxadIconButton
            >
              <span class="material-icons"> delete </span>
            </button>
          </div>
        </div>
      </ng-container>

      <!-- html, banner, video, push, native, vast -->
      <ng-container *ngIf="!isClickUrlType">
        <div
          (click)="deleteEl(index, true)"
          *ngIf="isNewCreative"
          class="creative__delete"
        >
          <span class="material-icons"> close </span>
        </div>

        <adxad-form-field class="creative__fields">
          <adxad-input
            label="{{ 'label_name' | transloco }}"
            placeholder="{{ 'placeholder_name' | transloco }}"
            formControlName="name"
          ></adxad-input>
        </adxad-form-field>

        <adxad-form-field
          *ngIf="mediaType === 'vast'"
          class="creative__fields"
        >
          <adxad-input
            formControlName="videoUrl"
            label="{{ 'label_videoUrl' | transloco }}"
            placeholder="{{ 'placeholder_videoUrl' | transloco }}"
          ></adxad-input>
        </adxad-form-field>

        <adxad-tabs-nav *ngIf="mediaType === 'html'">
          <adxad-tab-link
            [class.active]="showTab === 1"
            (click)="changeTab(1)"
          >
            {{ 'preview' | transloco }}
          </adxad-tab-link>
          <adxad-tab-link
            (click)="changeTab(2)"
            [class.active]="showTab === 2"
          >
            HTML
          </adxad-tab-link>

          <div
            *ngIf="!this.isNewCreative"
            [ngClass]="status"
            class="creative__status creative__status_html"
          >
            {{ status }}
          </div>
        </adxad-tabs-nav>

        <adxad-label *ngIf="mediaType !== 'html'"> {{ 'preview' | transloco }} </adxad-label>
        <div
          class="creative__preview"
          *ngIf="showTab === 1"
        >
          <div
            class="creative__status"
            [ngClass]="status"
            *ngIf="isShowStatus"
          >
            {{ status }}
          </div>

          <!-- image -->
          <div
            *ngIf="mediaType === 'image'"
            class="creative__preview-image-wrapper"
          >
            <img
              [src]="creative.sourceUrl"
              alt=""
              class="creative__preview-image"
            />
          </div>

          <!-- native -->
          <img
            class="creative__preview-native"
            *ngIf="mediaType === 'native'"
            [src]="creative.sourceUrl"
            alt=""
          />

          <!-- push -->
          <div
            class="creative__preview-push"
            *ngIf="mediaType === 'push'"
          >
            <div
              class="creative__preview-push-icon-placeholder"
              *ngIf="!creative.iconUrl"
              (click)="uploadIcon.emit()"
            ></div>

            <img
              class="creative__preview-push-icon"
              *ngIf="creative.iconUrl"
              [src]="creative.iconUrl"
              alt=""
            />

            <div class="creative__preview-push-image-wrapper">
              <img
                class="creative__preview-push-image"
                [src]="creative.sourceUrl"
                alt=""
              />
            </div>
          </div>

          <!-- video -->
          <div
            class="creative__preview-video-wrapper"
            *ngIf="mediaType === 'video'"
          >
            <video
              class="creative__preview-video"
              controls
            >
              <source
                [src]="creative.sourceUrl"
                type="video/mp4"
              />
            </video>
          </div>

          <!-- vast -->
          <div
            *ngIf="mediaType === 'vast'"
            class="creative__preview-video-wrapper"
          >
            <video
              class="creative__preview-video"
              controls
            >
              <source
                [src]="creative.videoUrl"
                type="video/mp4"
              />
            </video>
          </div>

          <!-- html -->
          <div
            class="creative__iframe-wrapper"
            *ngIf="mediaType === 'html'"
          >
            <iframe
              [srcdoc]="creative.html | escapeHtml"
              sandbox="allow-scripts allow-forms allow-popups allow-same-origin"
              class="creative__iframe"
            ></iframe>
          </div>
        </div>

        <!-- file size for new creatives -->
        <div
          class="creative__file-size"
          *ngIf="mediaType === 'image' && (creative.data?.size || creative.fileSize)"
        >
          {{ 'fileSize' | transloco }} {{ creative.data ? creative.data.size / 1000 : creative.fileSize }} kB
        </div>

        <adxad-form-field *ngIf="mediaType === 'html' && showTab === 2">
          <adxad-textarea
            placeholder="{{ 'placeholder_htmlCode' | transloco }}"
            formControlName="html"
            [fixWidth]="true"
            height="323"
          ></adxad-textarea>
        </adxad-form-field>

        <adxad-form-field
          class="creative__fields"
          *ngIf="mediaType === 'native' || mediaType === 'push'"
        >
          <adxad-input
            label="{{ 'label_creativeTitle' | transloco }}"
            placeholder="{{ 'placeholder_enterCreativeTitle' | transloco }}"
            formControlName="title"
          ></adxad-input>
        </adxad-form-field>

        <adxad-form-field
          class="creative__fields"
          *ngIf="mediaType === 'push' || mediaType === 'native'"
        >
          <adxad-textarea
            placeholder="{{ 'placeholder_enterCreativeDescription' | transloco }}"
            formControlName="description"
            label="{{ 'label_creativeDescription' | transloco }}"
            [fixWidth]="true"
          ></adxad-textarea>
        </adxad-form-field>

        <adxad-form-field
          class="creative__fields"
          *ngIf="mediaType === 'native'"
        >
          <adxad-input
            label="{{ 'label_brandName' | transloco }}"
            placeholder="{{ 'placeholder_enterBrandName' | transloco }}"
            formControlName="brandName"
          ></adxad-input>
        </adxad-form-field>
      </ng-container>
    </ng-container>

    <div
      class="creative__error"
      *ngIf="creative.error && creative.error.length"
    >
      <img
        class="creative__error-image"
        src="/assets/images/creative-error.svg"
        alt="{{ 'oops' | transloco }}"
      />
      <div class="creative__error-title">{{ 'oops' | transloco }}</div>
      <div class="creative__error-name">{{ creative.name | truncate: 20 }}</div>
      <div class="creative__error-message">{{ creative.error }}</div>
      <div class="creative__error-action">
        <button
          (click)="openImageCropperForm()"
          *ngIf="creative.isResize"
          adxadStrokedButton
        >
          {{ 'action_resize' | transloco }}
        </button>
        <button
          adxadButton
          (click)="deleteEl(index)"
        >
          {{ 'action_delete' | transloco }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
