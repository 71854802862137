<div class="header">
  <div class="adxad-grid-header">
    <button
      (click)="openForm()"
      adxadButton
      class="adxad-grid-header__add-btn red --wizard-step-1"
      icon="add"
    >
      {{ 'action_createCampaign' | transloco }}
    </button>
  </div>

  <section
    [ngClass]="{'hide': isNotCreated}"
    class="adxad-toolbar"
  >
    <div class="adxad-actions">
      <button
        (click)="multiChangeStatus(Status.stopped)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn --adaptive-btn"
        icon="pause"
        iconHoverColor="error"
      >
        {{ 'action_stop' | transloco }}
      </button>

      <button
        (click)="multiChangeStatus(Status.active)"
        [disabled]="isDisableMultiActions"
        adxadStrokedButton
        class="action-btn --adaptive-btn"
        icon="play_arrow"
        iconHoverColor="success"
      >
        {{ 'action_activate' | transloco }}
      </button>

      <div
        [adxadTooltip]="isDisableMultiActions? ('tooltips_campaignsCopyObjects' | transloco) : null"
        class="action-btn"
      >
        <button
          (click)="multiCopyCampaign()"
          [disabled]="isDisableMultiActions"
          adxadStrokedButton
          icon="filter_none"
          class="--adaptive-btn"
        >
          {{ 'action_copy' | transloco }}
        </button>
      </div>

      <div
        [adxadTooltip]="isDisableMultiActions ? ('tooltips_campaignsDeletingObjects' | transloco) : null"
        class="action-btn"
      >
        <button
          (click)="multiChangeStatus(Status.archived)"
          [disabled]="isDisableMultiActions"
          adxadStrokedButton
          icon="delete_outline"
          iconHoverColor="error"
          class="--adaptive-btn"
        >
          {{ 'action_delete' | transloco }}
        </button>
      </div>
    </div>

    <div class="set-bid">
      <adxad-multi-set-bid
        (setBid)="multiSetBid($event)"
        [disabled]="isDisableMultiActions"
      ></adxad-multi-set-bid>

      <adxad-multi-set-commission
        (setCustomCommission)="multiSetCustomCommission($event)"
        [disabled]="isDisableMultiActions"
        *ngIf="roles.isManagers()"
      ></adxad-multi-set-commission>
    </div>
  </section>

  <section class="filter-toolbar">
    <adxad-calendar
      (changeDates)="filter.changeCalendarDates($event)"
      [from]="filter.calendar?.value?.calendar.from"
      [to]="filter.calendar?.value?.calendar.to"
    />

    <div class="filter-toolbar__actions">
      <adxad-dg-column-selector
        *ngIf="columns.length"
        [columns]="columns"
        class="--adaptive-action"
        gridPrefix="campaignsGridHiddenColumns"
      />

      <button
        (click)="goToDeleted()"
        adxadStrokedButton
        class="filter-toolbar__deleted-btn"
      >
        {{ 'action_deleted' | transloco }}
      </button>

      <adxad-filter-actions
        (applyFilter)="applyFilter()"
        (clearFilters)="clearFilters()"
        (openFilter)="openFilter()"
        [count]="filter.count"
        [disabled]="isLoadingFilters"
        [notApplied]="filter.isNotApplied"
      />
    </div>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{'hide': isNotFound || isNotCreated}"
/>

<adxad-grid-not-found
  *ngIf="isNotFound"
  (clearFilters)="clearFilters()"
/>

<section
  *ngIf="isNotCreated"
  class="not-created"
>
  <img
    alt=""
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
  />

  <h2 class="not-created__title">{{ 'noCampaigns' | transloco }}</h2>

  <p class="not-created__text">
    {{ 'noCampaignsYet' | transloco }}
    <br />
    {{ 'createCampaignToWork' | transloco }}
  </p>

  <button
    adxadButton
    (click)="openForm()"
  >
    {{ 'action_create' | transloco }}
  </button>
</section>

<adxad-paginator (changePage)="loadGrid()" />
