<div
  class="campaigns-wizard"
  *ngIf="isShowWizard"
>
  <div class="campaigns-wizard__mask"></div>

  <div
    class="campaigns-wizard__container"
    [ngClass]="'_step' + activeStep"
  >
    <span
      class="material-icons campaigns-wizard__close-btn"
      (click)="close(activeStep)"
    >
      close
    </span>

    <div class="campaigns-wizard__progress-wrapper">
      <div
        class="campaigns-wizard__progress-bar"
        [style.width]="(100/3 * activeStep) + '%'"
      ></div>
    </div>

    <div class="campaigns-wizard__text">{{ steps[activeStep - 1] | transloco }}</div>

    <div
      class="material-icons campaigns-wizard__arrow"
      [ngClass]="'_step' + activeStep"
    >
      keyboard_arrow_right
    </div>

    <div
      class="material-icons campaigns-wizard__arrow __arrow2"
      [ngClass]="'_step' + activeStep"
      *ngIf="activeStep === 1"
    >
      keyboard_arrow_right
    </div>

    <div class="text-right">
      <button
        class="campaigns-wizard__action"
        adxadStrokedButton
        (click)="nextStep()"
        *ngIf="activeStep !== steps.length"
      >
        {{ 'action_next' | transloco }}
      </button>

      <button
        class="campaigns-wizard__action"
        adxadStrokedButton
        *ngIf="activeStep === steps.length"
        (click)="showMore()"
      >
        {{ 'action_knowMore' | transloco }}
      </button>
    </div>
  </div>
</div>
