import { NgModule } from '@angular/core';
import { GuiModule } from '../../../../gui/gui.module';
import { MassAddCreativesComponent } from './components/mass-add-creatives/mass-add-creatives.component';
import { NewCreativesFormComponent } from './components/new-creatives-form/new-creatives-form.component';
import { ChangeCreativesFormComponent } from './components/change-creatives-form/change-creatives-form.component';
import { CreativePreviewComponent } from './components/creative-preview/creative-preview.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ResizeCreativeFormComponent } from './components/resize-creative-form/resize-creative-form.component';
import { ImageCropperModule } from 'ngx-image-cropper';

const ENTRY_COMPONENTS = [
  MassAddCreativesComponent,
  NewCreativesFormComponent,
  ChangeCreativesFormComponent,
  CreativePreviewComponent,
  ResizeCreativeFormComponent
];

@NgModule({
  imports: [GuiModule, TranslocoModule, ImageCropperModule],
  declarations: [...ENTRY_COMPONENTS],
  exports: [MassAddCreativesComponent]
})
export class CreativesFormModule {}
