import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { CampaignsService } from '../../../campaigns/campaigns.service';
import { CampaignCreative, CampaignDetail, Dict, Dicts } from '../../../../../../core/interface';
import { NewCreativesFormComponent } from '../new-creatives-form/new-creatives-form.component';
import { ChangeCreativesFormComponent } from '../change-creatives-form/change-creatives-form.component';
import { DspService } from '../../../../../../core/services/dsp.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { APP_ROUTE, DESK_ROUTE } from '../../../../../../core/routes';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../../../core/services/profile.service';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-mass-add-creatives',
  templateUrl: './mass-add-creatives.component.html',
  styleUrls: ['./mass-add-creatives.component.scss']
})
export class MassAddCreativesComponent implements OnInit, OnDestroy {
  @ViewChild(NewCreativesFormComponent) newCreatives: NewCreativesFormComponent;
  @ViewChild(ChangeCreativesFormComponent) creatives: ChangeCreativesFormComponent;

  public isLoading = false;
  public isDndAreaShow = false;
  public campaign: CampaignDetail;
  public uploadUrl = '';
  public dict = {} as Dicts;
  public iframeForm: UntypedFormGroup;
  public maxSize = '1 Mb';
  public showTab = 1;
  public format = '';
  private destroyRef = inject(DestroyRef);
  private cursorPosition = 0;

  constructor(
    private dspService: DspService,
    private queryParamsService: QueryParamsService,
    private campaignsService: CampaignsService,
    private profileService: ProfileService,
    private alerts: AdxadAlerts,
    private fb: UntypedFormBuilder,
    private router: Router,
    private translate: TranslocoService,
    private globica: GlobicaService
  ) {}

  /**
   * @return {boolean} flag is image format
   */
  get isImageForm(): boolean {
    return (
      this.campaign.mediaType.id !== 'html' &&
      this.campaign.mediaType.id !== 'pops' &&
      this.campaign.mediaType.id !== 'vast' &&
      this.campaign.mediaType.id !== 'interstitial'
    );
  }

  /**
   * @return {boolean} is popunder or interstitial creative
   */
  get isPopsType(): boolean {
    return this.campaign.mediaType.id === 'pops' || this.campaign.mediaType.id === 'interstitial' || this.campaign.mediaType.id === 'vast';
  }

  ngOnInit(): void {
    if (this.profileService.profile.bigFileEnabled) {
      this.maxSize = '10 Mb';
    }

    const campaignId = this.queryParamsService.get()['campaignId'];

    /** Check campaign */
    if (!campaignId) {
      this.alerts.warning('No campaign id');

      this.back();
      return;
    }

    /** load dict */
    this.loadData(campaignId);

    /** set drag & drop listener */
    document.ondragover = e => {
      if (document.body.classList.contains('modal-open')) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      this.isDndAreaShow = true;
    };
  }

  /**
   * Set cursor position on blur field
   *
   * @param {FocusEvent} e
   */
  setCursorPosition(e: FocusEvent): void {
    this.cursorPosition = e.target['selectionStart'];
  }

  /**
   * Leave drag & drop area
   */
  dragLeave(): void {
    this.isDndAreaShow = false;
  }

  /**
   * Drop file
   *
   * @param e
   */
  drop(e): void {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      Array.from(e.dataTransfer.files).forEach((file: File) => {
        this.newCreatives.addFileCreative(file);
      });
    }
    this.isDndAreaShow = false;
    /** Track globica event */
    this.globica.trackEventGoals('formDragNDrop_drag');
  }

  /**
   * Set name of media type and advert format
   */
  setFormat(): void {
    this.format = this.campaign.mediaType.value;

    if (!this.isPopsType && this.campaign.mediaType.id !== 'push') {
      this.format += ' ' + this.campaign.format;
    }
  }

  /**
   * @return {boolean} is push campaign
   */
  get isPush(): boolean {
    return this.campaign.mediaType.id === 'push';
  }

  /**
   * @return {boolean} is native campaign
   */
  get isNative(): boolean {
    return this.campaign.mediaType.id === 'native';
  }

  /**
   * @return {boolean} is vast campaign
   */
  get isVast(): boolean {
    return this.campaign.mediaType.id === 'vast';
  }

  /**
   * Load placeholders dict
   *
   * @param {string} campaignId
   */
  loadData(campaignId: string): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    this.dspService
      .getPlaceholders(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          this.dict.holders = result;
          this.loadCampaign(campaignId);
        },
        error: () => {
          this.isLoading = false;
          this.back();
        }
      });
  }

  /**
   * @return {string} title for pops, html & interstitial
   */
  get title(): string {
    switch (this.campaign.mediaType.id) {
      case 'html': {
        return this.translate.translate('label_addHtmlCreative');
      }
      case 'pops': {
        return this.translate.translate('label_addPopunder');
      }
      case 'vast': {
        return this.translate.translate('label_addVastCreative');
      }
      case 'interstitial': {
        return this.translate.translate('label_addInterstitialUrl');
      }
    }
  }

  /**
   * Create form for iframe
   */
  createIframeForm(): void {
    this.iframeForm = this.fb.group({
      name: ['', Validators.required],
      code: [this.isPopsType ? 'https://' : '', Validators.required]
    });

    /** Track globica event */
    this.iframeForm
      .get('name')
      .statusChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => {
        if (status === 'INVALID') {
          this.globica.trackEventGoals('formCreativeName_change', { valid: 0 });
        }
      });
  }

  /**
   * @return {string} placeholder for textarea
   */
  get placeholder(): string {
    switch (this.campaign.mediaType.id) {
      case 'html': {
        return this.translate.translate('placeholder_pasteHtmlHere');
      }
      case 'pops': {
        return this.translate.translate('placeholder_pastePopunderUrlHere');
      }
      case 'vast': {
        return this.translate.translate('placeholder_pasteVastUrlHere');
      }
      case 'interstitial': {
        return this.translate.translate('placeholder_pasteInterstitialUrlHere');
      }
    }
  }

  /**
   * Load campaign data
   *
   * @param {string} id
   */
  loadCampaign(id: string): void {
    if (this.isLoading || !id) {
      return;
    }
    this.isLoading = true;

    this.campaignsService
      .getCampaign({ id: id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result.status === 'OK') {
            this.campaign = result.data as CampaignDetail;

            this.setFormat();
            const mediaType = this.campaign.mediaType.id;
            if (mediaType === 'html' || mediaType === 'pops' || mediaType === 'interstitial' || mediaType === 'vast') {
              this.createIframeForm();
            }
          }
        },
        error: () => {
          this.isLoading = false;
          this.back();
        }
      });
  }

  /**
   * Upload files from disc
   */
  addFiles(): void {
    this.globica.trackEventGoals('buttonUploadFromDisk_click');

    const input = document.createElement('input');
    input.type = 'file';
    document.body.appendChild(input);
    input.setAttribute('multiple', 'true');
    input.onchange = e => {
      const files = (e.target as HTMLInputElement).files;
      if (!files || files.length < 1) {
        return;
      }

      Array.from(files).forEach((file: File) => {
        this.newCreatives.addFileCreative(file);
      });

      input.remove();
    };
    input.click();
  }

  /**
   * Upload file from url
   * Get file as Blob()
   * Check size and type
   * Preload file
   */
  uploadFromUrl(): void {
    this.globica.trackEventGoals('buttonUploadFromUrl_click');
    fetch(this.uploadUrl)
      .then(result => {
        if (result.status === 200) {
          return result.blob();
        } else {
          this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
        }
      })
      .then((blob: Blob) => {
        if (!blob) {
          return;
        }
        this.newCreatives.addFileCreative(blob as File);
      })
      .catch(error => {
        this.alerts.error(error, 3000);
      });
    this.uploadUrl = '';
  }

  /**
   * @return {boolean} flag for disable Url btn
   */
  isDisabledUrlBtn(): boolean {
    return !this.uploadUrl.length;
  }

  /**
   * Add placeholder in iframe
   * or replace all same holders
   *
   * @param {Dict} holder
   */
  toggleHolder(holder: Dict): void {
    const control = this.iframeForm.get('code');
    let code = control.value;

    this.globica.trackEventGoals('formCreativeHolder_click', { holder: holder.value });

    if (code.indexOf(holder.id) !== -1) {
      const re = new RegExp(holder.id, 'g');
      code = code.replace(re, '');
    } else {
      code = code.slice(0, this.cursorPosition) + holder.id + code.slice(this.cursorPosition);
    }
    control.setValue(code);
  }

  /**
   * @return {boolean} flag of disabled add creative btn
   */
  get isDisabledNonImageAddBtn(): boolean {
    return this.iframeForm.invalid || (this.isPopsType && this.isInvalidUrl);
  }

  /**
   * Add html, pops or interstitial creative in new creatives form
   */
  addNonImageCreative(): void {
    this.newCreatives.addNonImageCreative(this.iframeForm.value);
    this.iframeForm.reset();
    this.iframeForm.get('code').setValue(this.isPopsType ? 'https://' : '');

    this.iframeForm.markAsPristine();
    this.dict.holders.data.forEach(x => (x.selected = false));

    /** Track globica event */
    this.globica.trackEventGoals('buttonAddCreative_click');
  }

  /**
   * @return {boolean} url not included 'https://'
   */
  get isInvalidUrl(): boolean {
    const value = this.iframeForm.get('code').value;
    return this.isPopsType && !value.startsWith('https://') && !value.startsWith('http://');
  }

  /**
   * Add new creatives in campaign
   * Add controls in form
   *
   * @param creatives
   */
  addNewCreatives(creatives: CampaignCreative[]): void {
    this.creatives.addCreatives(creatives);
    this.campaign.creatives.push(...creatives);
  }

  /**
   * Change tab for html creative
   * @param tab
   */
  changeTab(tab: number): void {
    if (tab === 2 && !this.iframeForm.get('code').value.length) {
      return;
    }
    this.showTab = tab;

    /** Track globica event */
    this.globica.trackEventGoals(tab === 1 ? 'buttonHtml_click' : 'buttonPreview_click');
  }

  /**
   * Go back
   */
  back(buttonClick?: boolean): void {
    if (buttonClick) {
      this.globica.trackEventGoals('buttonGoBack_click');
    }
    this.router.navigate([APP_ROUTE.tradeDesk, DESK_ROUTE.campaigns]);
  }

  ngOnDestroy(): void {
    document.ondragover = null;
  }
}
