import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CampaignDetail, CreativeFormEl, NonImageCreative } from '../../../../../../core/interface';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, switchMap, throwError } from 'rxjs';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { CreativesFormService } from '../../creatives-form.service';
import { catchError } from 'rxjs/operators';
import { ProfileService } from '../../../../../../core/services/profile.service';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { MessageService } from '../../../../../../core/services/message.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-new-creatives-form',
  templateUrl: './new-creatives-form.component.html',
  styleUrls: ['./new-creatives-form.component.scss']
})
export class NewCreativesFormComponent implements OnInit {
  @Input() campaign: CampaignDetail;
  @Output() private created = new EventEmitter();
  public maxFileSize = 1048576;

  public isLoading = false;
  public newCreatives = [] as CreativeFormEl[];
  public form: UntypedFormGroup;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private creativesService: CreativesFormService,
    private profileService: ProfileService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private globica: GlobicaService,
    public roles: RolesService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    if (this.profileService.profile.bigFileEnabled) {
      this.maxFileSize = 10485760;
    }

    this.messageService
      .get('resizeComplete')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          if (data.submit) {
            this.addFileCreative(data.value as File);
          }
        }
      });

    this.createForm();
  }

  /**
   * Create reactive form
   */
  createForm(): void {
    this.form = this.fb.group({
      campaignId: this.campaign.id,
      creatives: this.fb.array([])
    });
  }

  /**
   * @return {UntypedFormArray} creatives form array
   */
  get creatives(): UntypedFormArray {
    return this.form.get('creatives') as UntypedFormArray;
  }

  /**
   * @return {UntypedFormArray} creative form group by index from creatives form array
   * @param {number} i
   */
  getCreative(i: number): UntypedFormGroup {
    return this.form.get(['creatives', i]) as UntypedFormGroup;
  }

  /**
   * @return {number} Count of new and old creatives by type: success | error
   * @param {string} type
   */
  creativesCount(type: string): number {
    switch (type) {
      case 'success': {
        return this.newCreatives.filter(x => !x.error).length;
      }
      case 'error': {
        return this.newCreatives.filter(x => x.error).length;
      }
      default: {
        return 0;
      }
    }
  }

  /**
   * Add image
   * Add new control in form
   * Add object in newCreatives list
   * Check valid of file
   * Preload file
   * If preload success, enable form group
   *
   * @param file
   */
  addFileCreative(file: File | Blob): void {
    /** add to form */
    const group = this.fb.group({
      fileId: '',
      name: [file['name'] || this.translate.translate('newCreative'), Validators.required]
    });

    if (this.campaign.mediaType.id === 'native') {
      group.addControl('title', this.fb.control('', Validators.required));
      group.addControl('description', this.fb.control('', Validators.required));
      group.addControl('brandName', this.fb.control(''));
    }

    if (this.campaign.mediaType.id === 'push') {
      group.addControl('iconId', this.fb.control('', Validators.required));
      group.addControl('title', this.fb.control('', Validators.required));
      group.addControl('description', this.fb.control('', Validators.required));
    }
    group.disable();
    this.creatives.push(group);

    const creative = {
      isLoading: true,
      data: file,
      name: file['name'] || this.translate.translate('newCreative')
    } as CreativeFormEl;
    this.newCreatives.push(creative);

    this.checkValid(file)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() => {
          return this.creativesService.preloadCreativeFile(this.campaign.id, file);
        }),
        catchError(error => {
          creative.isLoading = false;
          this.createIncorrectPreview(creative);

          /** Track globica event */
          this.globica.trackEventGoals('messageCreativeError_show', { error: creative.error });

          return throwError({
            error: {
              message: error
            }
          });
        })
      )
      .subscribe({
        next: result => {
          creative.fileId = result.data.fileId;
          this.createPreview(creative);
          group.get('fileId').setValue(result.data.fileId);
          group.enable();
        },
        error: error => {
          creative.error = error.error?.message ? error.error.message : this.translate.translate('error_couldNotUploadFile');
          creative.isLoading = false;
        }
      });
  }

  /**
   * Add html, pops or interstitial creative
   * HTML controls: name, html
   * Pops & interstitial controls: name, clickUrl
   *
   * @param {NonImageCreative} data
   */
  addNonImageCreative(data: NonImageCreative): void {
    const controlName: string =
      this.campaign.mediaType.id === 'html' ? 'html' : this.campaign.mediaType.id === 'vast' ? 'videoUrl' : 'clickUrl';

    // add to form */
    const group = this.fb.group({
      name: [data.name, Validators.required]
    });

    group.addControl(controlName, this.fb.control(data.code, Validators.required));
    this.creatives.push(group);

    const creative = {
      isLoading: false,
      name: data.name
    } as CreativeFormEl;

    creative[controlName] = data.code;
    this.newCreatives.push(creative);
  }

  /**
   * Create file preview
   *
   * @param {CreativeFormEl} creative
   */
  createPreview(creative: CreativeFormEl) {
    const reader = new FileReader();
    reader.onload = (x: any) => {
      creative.sourceUrl = x.target.result;
      creative.isLoading = false;
    };
    reader.readAsDataURL(creative.data);
  }

  /**
   * Create incorrect file preview
   *
   * @param {CreativeFormEl} creative
   */
  createIncorrectPreview(creative: CreativeFormEl) {
    const mediaType = this.campaign.mediaType.id;
    const reader = new FileReader();

    const availableImageType =
      creative.data.type === 'image/png' || creative.data.type === 'image/jpg' || creative.data.type === 'image/jpeg';

    reader.onload = (x: any) => {
      creative.incorrectImageUrl = x.target.result;

      const image = new Image();
      image.src = creative.incorrectImageUrl;

      image.onload = () => {
        if (mediaType === 'image') {
          let campaignFormat = this.campaign.format.split('x').map(Number);
          creative.isResize = availableImageType && image.width >= campaignFormat[0] && image.height >= campaignFormat[1];
        } else if (mediaType === 'native') {
          creative.isResize = availableImageType && image.width >= 400 && image.height >= 300;
        } else if (mediaType === 'push') {
          creative.isResize = availableImageType && image.width >= 428 && image.height >= 328;
        }
      };
      creative.isLoading = false;
    };
    reader.readAsDataURL(creative.data);
  }

  /**
   * Remove creative from list of new creatives
   * Remove control from form array
   * Send clear preloaded file request
   *
   * @param {number} i
   */
  removeCreative(i: number): void {
    this.newCreatives.splice(i, 1);
    this.creatives.removeAt(i);

    if (this.newCreatives[i]?.fileId) {
      const data = {
        campaignId: this.campaign.id,
        fileId: this.newCreatives[i].fileId
      };
      this.creativesService.clearPreloadedFiles(data).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
  }

  /**
   * Check size, media type and advert format
   *
   * @param {File | Blob} file
   */
  checkValid(file: File | Blob): Observable<any> {
    const result = new Subject();
    const mediaType = this.campaign.mediaType.id;

    if (file.size >= this.maxFileSize) {
      /** check max size */
      const size = this.profileService.profile.bigFileEnabled ? '10 Mb' : '1 Mb';
      result.error(this.translate.translate('error_isTooLargeToUpload') + ' ' + size);
      return result.asObservable();
    }

    if ((mediaType === 'image' || mediaType === 'native' || mediaType === 'push') && !file.type.startsWith('image/')) {
      /** check media type */
      result.error(this.translate.translate('error_isNotSuitableForThisCampaign'));
      return result.asObservable();
    }

    if (mediaType === 'image' || mediaType === 'native' || mediaType === 'push') {
      /** check advert format */
      const img = new Image();
      img.onload = () => {
        if (mediaType === 'image') {
          if (img.width + 'x' + img.height !== this.campaign.format) {
            result.error(this.translate.translate('error_hasWrongAdvertFormat', { campaignFormat: this.campaign.format }));
          } else {
            result.next(true);
          }
        } else if (mediaType === 'native') {
          if (img.width > 1500 || img.height > 1125) {
            result.error(this.translate.translate('error_hasWrongFormatResizeItTo', { size: '1500 x 1125' }));
          } else if (this.checkRatio(img.width, img.height) !== '4x3') {
            result.error(this.translate.translate('error_hasWrongFormatAspectRatioMustBe', { ratio: '4 x 3' }));
          } else {
            result.next(true);
          }
        } else if (mediaType === 'push') {
          if (img.width !== 428 || img.height !== 328) {
            result.error(this.translate.translate('error_hasWrongFormatResizeItTo', { size: '428 x 328' }));
          } else {
            result.next(true);
          }
        }
      };
      img.src = URL.createObjectURL(file);
    }
    return result.asObservable();
  }

  /**
   * Check aspect ratio of image
   *
   * @param w
   * @param h
   */
  checkRatio(w, h): string {
    function gcd(a, b) {
      return b === 0 ? a : gcd(b, a % b);
    }

    const r = gcd(w, h);
    return w / r + 'x' + h / r;
  }

  /**
   * Upload icon for Push creatives
   *
   * @param {number} i
   */
  uploadIcon(i: number): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = e => {
      const files = (e.target as HTMLInputElement).files;
      if (!files || files.length < 1) {
        return;
      }

      Array.from(files).forEach((file: File) => {
        if (!file.type.startsWith('image/')) {
          return;
        }

        const img = new Image();
        img.onload = () => {
          if (img.width !== 192 || img.height !== 192) {
            this.alerts.error(this.translate.translate('error_hasWrongFormatResizeItTo', { size: '192 x 192' }));
            return;
          } else {
            this.creativesService
              .preloadCreativeIcon(this.campaign.id, file)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(result => {
                this.creatives.at(i).get('iconId').setValue(result.data.fileId);
                const reader = new FileReader();
                reader.onload = (x: any) => {
                  this.newCreatives[i].iconUrl = x.target.result;
                };
                reader.readAsDataURL(file);
              });
          }
        };
        img.src = URL.createObjectURL(file);
      });

      input.remove();
    };
    input.click();
  }

  /**
   * Clear new creatives list
   * Clear form array
   * Delete preloaded files
   */
  clear(deletePreloaded?: boolean): void {
    this.globica.trackEventGoals('buttonCancel_click');
    this.newCreatives = [];
    this.creatives.clear();

    if (deletePreloaded) {
      this.deletePreloadedFiles();
    }
  }

  /**
   * Sent clear preloaded files request
   */
  deletePreloadedFiles(): void {
    this.creativesService.clearPreloadedFiles({ campaignId: this.campaign.id }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  /**
   * @return {boolean} flag of disable Add button
   */
  isDisableAddBtn(): boolean {
    let flag = false;

    if (this.isLoading || this.form.invalid || !this.form.value['creatives'] || !this.form.value['creatives'].length) {
      flag = true;
    }

    return flag;
  }

  /**
   * Save new creatives
   */
  submit(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.creativesService
      .createCreatives(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;

          if (result.status === 'OK') {
            this.clear();
            this.created.emit(result.data);
            this.alerts.success(this.translate.translate('alert_creativesSuccessfullyAdded'), 3000);

            /** Track globica event */
            this.globica.trackEventGoals('alertCreativeAdded_show');
          }
        },
        error: error => {
          this.isLoading = false;

          /** Track globica event */
          this.globica.trackEventGoals('alertCreativeAddError_show', { error });
        }
      });

    /** Track globica event */
    const globicaParams = {
      campaign_id: this.campaign.id,
      media_format: this.campaign.mediaType.value,
      format: this.campaign.format,
      creatives_count: this.creatives.length
    };
    this.globica.trackEventGoals('buttonAddAllCreatives_click', globicaParams);
  }
}
