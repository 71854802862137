<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ 'modal_titleCopyCreativesToCampaign' | transloco }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <div class="creatives-names">
      <div class="creatives-names__title">{{ names.length + ' ' + ((names.length > 1 ? 'creatives' : 'creative') | transloco) }}</div>
      <ul class="creatives-names__list">
        <li
          class="creatives-names__name"
          *ngFor="let name of names"
        >
          {{ name }}
        </li>
      </ul>
    </div>

    <div class="field-label">{{ 'label_campaignName' | transloco }}</div>
    <adxad-form-field class="w100">
      <adxad-select
        placeholder="{{ 'placeholder_selectCampaign' | transloco }}"
        formControlName="campaignId"
        [options]="dict.campaigns.data"
        [filterable]="true"
        [lazy]="dict.campaigns.isLazy"
        (scrollOnBottom)="loadCampaigns()"
        (filterValue)="filterCampaigns($event)"
        (selectOption)="filterCampaigns('')"
      ></adxad-select>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        adxadStrokedButton
        (click)="close()"
        class="w100"
      >
        {{ 'action_cancel' | transloco }}
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
        (click)="submit()"
        [isLoading]="isLoadingSubmit"
      >
        {{ 'action_copy' | transloco }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
