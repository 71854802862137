import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { DynamicGridComponent } from '../../../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../../../ui/modules/paginator/paginator.component';
import { DspService } from '../../../../../../core/services/dsp.service';
import { QueryParamsService } from '../../../../../../core/services/query-params.service';
import { RolesService } from '../../../../../../core/services/roles.service';
import { AdxadAlerts } from '../../../../../../ui/modules/alerts/components/alerts/alerts.component';
import { Creative, CreativeData, DynamicGridAction, FilterChanges, FiltersConfig, GridRequest } from '../../../../../../core/interface';
import { CreativesService } from '../../creatives.service';
import { CreativeViewComponent } from '../creative-view/creative-view.component';
import { AdxadSidebarModal } from '../../../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { FiltersService } from '../../../../../../core/services/filters.service';
import { BreadcrumbsService } from '../../../../../../core/services/breadcrumbs.service';
import { SidebarFilterComponent } from '../../../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';
import { GlobicaService } from '../../../../../../core/services/globica.service';
import { APP_ROUTE, DESK_ROUTE } from '../../../../../../core/routes';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-creatives-deleted-grid',
  templateUrl: './creatives-deleted-grid.component.html',
  styleUrls: ['./creatives-deleted-grid.component.scss'],
  providers: [FiltersService]
})
export class CreativesDeletedGridComponent implements OnInit {
  @ViewChild(DynamicGridComponent) dynamicGrid: DynamicGridComponent;
  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;

  public isLoading = false;
  public isNotFound = false;
  public dict = {
    teams: [],
    users: [],
    format: [],
    mediaType: [],
    campaigns: []
  };

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<CreativeData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.creativesService.getDeletedCreatives(data))
  );

  constructor(
    public filter: FiltersService,
    private dspService: DspService,
    private creativesService: CreativesService,
    private queryParamsService: QueryParamsService,
    private breadcrumbs: BreadcrumbsService,
    private roles: RolesService,
    private alerts: AdxadAlerts,
    private router: Router,
    private sidebarModal: AdxadSidebarModal,
    private translate: TranslocoService,
    private globica: GlobicaService
  ) {}

  ngOnInit(): void {
    this.paginator.init();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: CreativeData) => {
        this.isLoading = false;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.loadFilters();
  }

  /**
   * Load filters list
   */
  loadFilters(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.dspService
      .getFilters('creatives/archived')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, status }: FiltersConfig) => {
          this.isLoading = false;

          if (status === 'OK') {
            this.subscribeFilter();
            this.filter.init({ data });
            this.loadGrid();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Subscribe on filter changes
   */
  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data && data.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.active,
      direction: this.dynamicGrid && this.dynamicGrid.sort && this.dynamicGrid.sort.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page,
      filter: this.filter.request
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Set creatives grid
   * @param {CreativeData} result
   */
  private setGrid(result: CreativeData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      this.isNotFound = true;
    }
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not found flags set false
   */
  resetGrid(): void {
    this.isNotFound = false;

    if (this.dynamicGrid) {
      this.dynamicGrid.clearGrid();
    }

    if (this.paginator) {
      this.paginator.clearList();
    }
  }

  /**
   * Check actions in grid
   * restore - restore creative
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }
    const creative = action.value as Creative;

    if (action.action === 'restore') {
      this.restoreCreatives([creative]);
    }

    if (action.action === 'openView') {
      if (!action.value.id) {
        return;
      }
      this.openSidebarModal(action.value.id);
    }
  }

  /**
   * @return {boolean} flag to disable multi actions
   */
  isDisableMultiActions(): boolean {
    return !(this.dynamicGrid && this.dynamicGrid.getChecked().length);
  }

  /**
   * Restore selected creatives
   */
  multiRestore(): void {
    const creatives = this.dynamicGrid.getChecked() as Creative[];
    const ids = creatives.map(x => x.id);

    /** Track globica event */
    this.globica.trackEventGoals('buttonRestore_click', {
      objects_type: 'creatives',
      ids
    });
    this.restoreCreatives(this.dynamicGrid.getChecked());
  }

  /**
   * Restore creatives
   *
   * @param {Creative[]} creatives
   */
  restoreCreatives(creatives: Creative[]): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const data = {
      ids: creatives.map(x => x.id),
      status: 0
    };

    this.creativesService
      .changeCreativesStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoading = false;
          if (result.status === 'OK') {
            this.alerts.success(this.translate.translate('alert_creativesHasBeenRestored'), 3000);
            this.loadGrid();
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  /**
   * Open detail creative view
   * Reload grid if it was restored
   *
   * @param {string} id
   */
  openSidebarModal(id: string): void {
    if (!id || typeof id !== 'string') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    this.breadcrumbs.reset();
    this.sidebarModal
      .open(CreativeViewComponent, {
        data: {
          id: id
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data && data.submit) {
          this.loadGrid();
        }
      });
  }

  /**
   * Open sidebar filter
   */
  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  /**
   * Apply filter by btn
   * Reset current page
   */
  applyFilter(): void {
    this.paginator.resetPage();
    this.loadGrid();
  }

  /**
   * Reset current page
   * Clear filter values
   * Reload grid
   */
  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  /**
   * Go to creatives grid
   * Set filter params
   */
  goBack(): void {
    const url = APP_ROUTE.tradeDesk + '/' + DESK_ROUTE.creatives;
    const queryParams = this.queryParamsService.get('f_');

    this.router.navigate([url], { queryParams });
  }
}
